import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import styles from "./addVendorContact.module.scss";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  styled,
} from "@mui/material";
import { IAddTaskDrawerProps, IContactDetails } from "../../../interfaces";
import { SMSSwitch, StyledDrawer } from "../../../components";
import { validateContact } from "../../../utils/ContactValidation";
import IAddContactDrawerProps from "../../../interfaces/IAddContactDrawerProps";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { useTranslation } from "react-i18next";
import { userService } from "../../../api";

const AddVendorContact: React.FC<IAddContactDrawerProps> = (props) => {
  const {
    vendorId,
    open,
    onClose = () => { },
    onSaveContact = () => { },
    editData,
    rowIndex,
  } = props;

  const [addContactOpen, setAddContactOpen] = React.useState(open);
  const [edata, setEdata] = useState();
  const [editContactTab, setEditContactTab] = React.useState(false);
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const [initialContactDetails, setInitialcontactDetails] =
    useState<IContactDetails>({
      firstName: "",
      lastName: "",
      email: "",
      contactNo: "",
      alternatePhoneNumber: "",
      canReceiveEmail: true,
      sendSmsToPhone: "",
      canReceiveSms: false,
      isDeleted: false,
      contactId: "",
      vendorId: 0,
    });
  const [contactDetails, setContactDetails] = useState<IContactDetails>({
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    alternatePhoneNumber: "",
    canReceiveEmail: true,
    sendSmsToPhone: "",
    canReceiveSms: false,
    isDeleted: false,
    contactId: "",
    vendorId: 0
  });
  const [savingData, setSavingData] = useState(false);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    primaryPhone: false,
  });
  const [touchedFields, setTouchedFields] = useState<Record<any, any>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [mode, setMode] = useState("");
  // toggle event for drawer
  const handleDrawerToggle = () => {
    setAddContactOpen(false);
    // if (!props.editData) {
    //   setContactDetails(initialContactDetails);
    //   setValidationErrors({});
    // }
    setContactDetails(initialContactDetails);
    setErrors({
      firstName: false,
      lastName: false,
      email: false,
      primaryPhone: false,
    });
    setTouchedFields({});
    setValidationErrors({});
    setFormTouched(false);
    if (props.onClose) {
      props.onClose();
    }
  };
  useEffect(() => {
    const isValid = validateForm();
    setIsFormValid(isValid);
  }, [contactDetails]);

  const handleBlur = (field: any) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
    if (!formTouched) {
      setFormTouched(true); // Set formTouched to true on first blur
    }
  };
  const handlePhoneNumberChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);

    setContactDetails({
      ...contactDetails,
      [fieldName]: formattedPhoneNumber,
    });
  };
  const validateForm = () => {

    const newErrors = {
      firstName: contactDetails.firstName === "",
      lastName: contactDetails.lastName === "",
      email: contactDetails.email === "",
      primaryPhone: contactDetails.contactNo === "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(Boolean);
  };
  useEffect(() => {
    setEdata(props.editData);
    if (props.editData) {
      setEditContactTab(true);
      setContactDetails({
        firstName: props.editData.firstName,
        lastName: props.editData.lastName,
        email: props.editData.email,
        contactNo: props.editData.contactNo,
        //props.editData.contactNo == "null" ? "" : props.editData.contactNo,
        alternatePhoneNumber: props.editData.alternatePhoneNumber,
        // props.editData.alternatePhoneNumber == "null"
        //   ? ""
        //   : props.editData.alternatePhoneNumber,
        canReceiveEmail: props.editData.canReceiveEmail,
        sendSmsToPhone: props.editData.sendSmsToPhone,
        canReceiveSms: props.editData.canReceiveSms,
        isDeleted: props.editData.isDeleted,
        contactId: props.editData.contactId,
        vendorId: props.editData.vendorId,
      });
    }
  }, [props.editData]);

  // Handle the form submission
  const handleContactSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (validateForm()) {
      setSavingData(true);
      e.preventDefault();
      const formDetailsWithUserId = {
        ...contactDetails,
        sendSmsToPhone: contactDetails.canReceiveSms ? contactDetails.sendSmsToPhone : "",
      };
      const errors = validateContact(formDetailsWithUserId);
      setTouchedFields({});
      setValidationErrors(errors);
      if (Object.keys(errors).length === 0) {
        const contactData: Partial<IContactDetails> = {
          firstName: contactDetails.firstName,
          lastName: contactDetails.lastName,
          email: contactDetails.email,
          contactNo: contactDetails.contactNo,
          alternatePhoneNumber: contactDetails.alternatePhoneNumber,
          canReceiveEmail: contactDetails.canReceiveEmail,
          sendSmsToPhone: contactDetails.sendSmsToPhone,
          canReceiveSms: contactDetails.canReceiveSms,
          isDeleted: contactDetails.isDeleted,
          contactId: contactDetails.contactId,
          vendorId: contactDetails.vendorId,
        };

        onSaveContact(
          contactDetails,
          contactDetails.contactId ? undefined : rowIndex ?? undefined,
        );
        setContactDetails(initialContactDetails);
        contactDetails.vendorId = props.vendorId ?? 0;
        contactDetails.sendSmsToPhone = contactDetails.canReceiveSms ? contactDetails.sendSmsToPhone : "";
        try {

          await userService.UpdateVendorContact(contactDetails).then((res) => {
            if (res.isSuccess) {
              onSaveContact(formDetailsWithUserId, rowIndex !== null ? rowIndex : undefined);
              handleDrawerToggle();
            }
          });
        }
        catch (error) {
          console.log("error", error);
        }

        if (props.onClose) {
          props.onClose();
        }
        setSavingData(false);
      }
      setSavingData(false);
    }
  };

  //Handle switch changes
  const handleSwitchChange = (e: any) => {
    setContactDetails({
      ...contactDetails,
      canReceiveSms: e.target.checked,
    });
    setValidationErrors({});
  };

  return (
    <StyledDrawer
      className={styles.rightDrawer}
      open={props.open}
      anchor="right"
      onClose={props.onClose}
    >
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        className={styles.addProjectcontainer}
        alignContent="space-between"
        onSubmit={handleContactSubmit}
      >
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          className={styles.formContainer}
        >
          {editContactTab ? (
            <Grid item xs={12}>
              <h3>Edit Contact</h3>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <h3 id="add_new_contact_title">Add New Contact</h3>
            </Grid>
          )}
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                value={contactDetails.firstName || ""}
                placeholder={t("AddVendorContact.first_name")}
                label={t("AddVendorContact.first_name")}
                variant="outlined"
                onChange={(e) =>
                  setContactDetails({
                    ...contactDetails,
                    firstName: e.target.value,
                  })
                }
                onBlur={() => handleBlur('firstName')}
                error={touchedFields.firstName && errors.firstName}
                helperText={touchedFields.firstName && errors.firstName ? "FirstName is required" : ""}
              />
            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                value={contactDetails.lastName || ""}
                placeholder={t("AddVendorContact.last_name")}
                label={t("AddVendorContact.last_name")}
                variant="outlined"
                onBlur={() => handleBlur('lastName')}
                error={touchedFields.lastName && errors.lastName}
                helperText={touchedFields.lastName && errors.lastName ? "LastName is required" : ""}
                onChange={(e) =>
                  setContactDetails({
                    ...contactDetails,
                    lastName: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                value={contactDetails.email || ""}
                type="email"
                placeholder={t("AddVendorContact.email")}
                label={t("AddVendorContact.email")}
                variant="outlined"
                onChange={(e) =>
                  setContactDetails({
                    ...contactDetails,
                    email: e.target.value,
                  })
                }
                onBlur={() => handleBlur('email')}
                error={touchedFields.email && errors.email}
                helperText={touchedFields.email && errors.email ? "Email is required" : ""}
              />
            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                fullWidth
                value={contactDetails.contactNo || ""}
                type="PrimarContactNumber"
                placeholder={t("AddVendorContact.primary_phone_placeholder")}
                label={t("AddVendorContact.primary_phone")}
                name="PrimarContactNumber"
                onChange={(e: any) => handlePhoneNumberChange(e, "contactNo")}
                onBlur={() => handleBlur('primaryPhone')}
                error={touchedFields.primaryPhone && errors.primaryPhone}
                helperText={touchedFields.primaryPhone && errors.primaryPhone ? "Primary Phone is required" : ""}
              />
            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                fullWidth
                value={contactDetails.alternatePhoneNumber || ""}
                type="AlternatecontactNumber"
                placeholder={t("AddVendorContact.alternate_phone_placeholder")}
                label={t("AddVendorContact.alternate_phone")}
                name="AlternatecontactNumber"
                onChange={(e: any) =>
                  handlePhoneNumberChange(e, "alternatePhoneNumber")
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              className=" fieldWrapper"
              gap={3}
              alignItems="center"
            >
               <FormControl component="fieldset"
                          >
              <label htmlFor="name"> {t("AddVendorContact.can_receive_sms")}</label>
              <SMSSwitch
                checked={contactDetails.canReceiveSms}
                onChange={(e) => {
                  const canReceiveSms = e.target.checked;
                  handleSwitchChange(e); // This should toggle `canReceiveSms`
                  setContactDetails({
                    ...contactDetails,
                    canReceiveSms,
                    sendSmsToPhone: canReceiveSms ? contactDetails.sendSmsToPhone : "", // Clear `sendSmsToPhone` if toggle is off
                  });
                }}
                value={contactDetails.canReceiveSms || ""}
                //defaultChecked
                inputProps={{ "aria-label": "recieve sms" }}
              />
              </FormControl>
            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <FormControl className="inlineSelect">
                <InputLabel id="sms_placeholder">{t("AddVendorContact.send_sms_to")}</InputLabel>
                <Select
                  labelId="sms_placeholder"
                  id="sms"
                  placeholder="SMS"
                  label={t("AddVendorContact.saving_btn")}
                  className="selectBox"
                  disabled={!contactDetails.canReceiveSms}
                  onChange={(e) => {
                    setContactDetails({
                      ...contactDetails,
                      sendSmsToPhone: e.target.value,
                    });
                    setValidationErrors({});
                  }}
                  value={contactDetails.sendSmsToPhone || ""}
                  input={<OutlinedInput notched label="Send SMS to Phone" />}
                >
                  {/* <MenuItem disabled className="selectPlaceHolder" value="">
                    Send SMS to Phone
                  </MenuItem> */}
                  {contactDetails.contactNo && (
                    <MenuItem value={contactDetails.contactNo}>
                      Primary: {contactDetails.contactNo}
                    </MenuItem>
                  )}
                  {contactDetails.alternatePhoneNumber && (
                    <MenuItem value={contactDetails.alternatePhoneNumber}>
                      Alternate: {contactDetails.alternatePhoneNumber}
                    </MenuItem>
                  )}
                </Select>
                {validationErrors.sendSmsToPhone && (
                  <FormHelperText error>
                    {validationErrors.sendSmsToPhone}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={styles.submitButtons + " submitButtons"}
        >
          <Button
            fullWidth
            variant="outlined"
            className="outline-button widthauto"
            onClick={handleDrawerToggle}
            name="Cancel"
          >
            {" "}
            {t("AddVendorContact.cancel_btn")}
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="primary-button widthauto"
            name="SaveContact"
            disabled={!isFormValid}
            startIcon={
              savingData ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            {savingData ? t("AddVendorContact.saving_btn") : t("AddVendorContact.save_btn")}{" "}
          </Button>
        </Grid>
      </Box>
    </StyledDrawer>
  );
};

export default AddVendorContact;
