import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./addVendor.module.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CollectionsIcon from "@mui/icons-material/Collections";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Snackbar,
  FormHelperText,
  Badge,
  IconButton,
  FormControlLabel,
  InputLabel,
  Typography,
  CircularProgress,
  OutlinedInput,
  Autocomplete,
  Divider,
  Chip,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiCloudUpload } from "@mdi/js";
import { AddVendorContact, DocumentViewerVendor, VendorContactList } from "../../../containers";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import vendorServices from "../../../api/services/VendorService";
import IAddressPropsDeatils from "../../../interfaces/IAddressPropsDetails";
import { IVendorTypeListProps } from "../../../interfaces/IVendorTypeListProps";
import {
  IContactDetails,
  ICountry,
  IState,
  IVendorParam,
} from "../../../interfaces";
import { billingService, userService } from "../../../api";
import { IVendor } from "../../../interfaces/IVendor";
import { useUserContext } from "../../../utils/UserContext";
import { validateVendor } from "../../../utils/VendorValidation";
import { decryptData } from "../../../utils/CryptoJS";
import UserAxios from "../../../api/UserAxios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import Swal from "sweetalert2";
import IDeleteVendorParam from "../../../interfaces/IDeleteVendorParam";
import { DeleteDialog } from "../../../components";
import { EntityType } from "../../../enums";
import { useTranslation } from "react-i18next";
const AddVendor: any = () => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  var userDetails: any = localStorage.getItem("userDetails");
  var userDetailsObj = decryptData(userDetails);
  const { t } = useTranslation();
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const [vendorTypesList, setvendorTypeList] = useState<IVendorTypeListProps[]>(
    []
  );
  const [deleteRequest, setDeleteRequest] = useState<IDeleteVendorParam | null>(
    null
  );
  const [dateValidationError, setDateValidationError] = useState(false);
  const [vendorTypeName, setvendorTypeName] = React.useState<number[]>([]);
  const initialVendorState = {
    userId: "",
    companyName: "",
    customerId: userDetailsObj.customerId,
    hasW9: false,
    coiExpiryDate: null,
    coiStartDate: null,
    companyURL: "",
    vendorTypeIds: [],
    contact: [],
    description: "",
    vendorId: 0,
    address1: "",
    address2: "",
    isActive: false,
    city: "",
    stateId: "",
    zipCode: "",
    addressId: 0,
    vendorNote: "",
    policyNumber: "",
    files: [],
  };
  const [formDetails, setFormDetails] = useState<IVendor>({
    userId: "",
    hasW9: false,
    coiExpiryDate: null,
    customerId: userDetailsObj.customerId,
    coiStartDate: null,
    companyName: "",
    companyURL: "",
    vendorTypeIds: [],
    contact: [],
    vendorId: 0,
    address1: "",
    address2: "",
    isActive: false,
    city: "",
    stateId: "",
    zipCode: "",
    addressId: 0,
    vendorNote: "",
    policyNumber: "",
    files: [],
  });
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const { email } = useUserContext();
  const navigate = useNavigate();
  const deleteDialogTitle = "Are you sure you want to delete this record?";
  const dialogCancelButtonText = "Cancel";
  const dialogDeleteButtonText = "Delete";
  const [states, setStates] = useState<IState[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  // const [errors, setErrors] = useState<{ [key in keyof IVendor]?: string }>({});
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const vendorId = location.state?.vendorId;
  const [contact, setContactDetails] = useState<IContactDetails[]>([]);
  const [addContactOpen, setAddContactOpen] = React.useState(false);
  const [editVendorTab, setEditVendorTab] = React.useState("false");
  const [attachmentCount, setAttachmentCount] = React.useState<number[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pageCount = Math.ceil(count / rowsPerPage);
  var userDetails: any = localStorage.getItem("userDetails");
  //var userDetailsObj = decryptData(userDetails);
  var customerId = parseInt(localStorage.getItem("customerId") as string, 10);
  const userId = localStorage.getItem("userId") as string;
  const [savingData, setSavingData] = useState(false);
  const [isFirstTimeClickedW9, setIsFirstTimeClickedW9] = useState(true);
  const [documentTypeId, setDocumentTypeId] = useState('');
  const [errors, setErrors] = useState({
    companyName: false,
    address1: false,
    state: false,
    city: false,
    zipCode: false,
    vendorTypeId: false,
    coiStartDate: false,
    coiEndDate: false
  });
  const [touchedFields, setTouchedFields] = useState<Record<any, any>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);

  useEffect(() => {
    const isValid = validateForm();
    setIsFormValid(isValid);
  }, [formDetails]);

  const handleBlur = (field: any) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
    if (!formTouched) {
      setFormTouched(true); // Set formTouched to true on first blur
    }
  };
  //Handle contact update
  const handleUpdateContacts = (updatedContacts: IContactDetails[]) => {
    setContactDetails(updatedContacts);
  };
  //Handle drawerOpen
  const handleDrawerOpen = () => {
    setAddContactOpen(true);
  };
  //Handle drawerClose
  const handleDrawerClose = () => {
    setAddContactOpen(false);
  };
  const handleRemoveFileClick = (file: any) => {
    removeFile(file.attachmentId, file.documents.name, file.entityTypeId);
  };
  const [isLoaderVisible, setLoaderIsVisible] = useState(false);
  // To remove Files from the uploaded file list
  const removeFile = async (attachmentId: number | undefined, name: string, entityTypeId: number) => {

    if (attachmentId === undefined) {
      setFormDetails((prevDetails) => {
        const updatedDetails = { ...prevDetails };
        updatedDetails.files = prevDetails.files.filter((file) => {
          return !(file.documents.name === name && file.entityTypeId === entityTypeId);
        });
        return updatedDetails;
      });
    } else {
      // If attachmentId exists, make the API call to delete the file permanently
      try {
        const response = await vendorServices.DeleteVendorDocumentAsync(attachmentId, userId);
        if (response.isSuccess === true) {
          setFormDetails((prevDetails) => {
            const updatedDetails = { ...prevDetails };
            updatedDetails.files = prevDetails.files.filter((file) => {
              return !(file.attachmentId === attachmentId && file.entityTypeId === entityTypeId);
            });

            return updatedDetails;
          });
        }
      } catch (error: any) {
        setError(error.response.data.errors.join(", "));
      }
    }
  };

  const handleDelete = (vendorId: number) => {
    setDeleteDialogOpen(true);
    setDeleteRequest({
      userId: userId,
      vendorId: vendorId,
    });
  };
  // Handle change of document type dropdown
  const handleDocumentTypeChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value; // Get the selected value
    setDocumentTypeId(selectedValue); // Set it to state
  };

  // Handle change of vendor type dropdown
  const handleVendorTypeChange = (event: SelectChangeEvent<typeof vendorTypeName>) => {
    const {
      target: { value },
    } = event;

    const updatedValue = typeof value === "string" ? value.split(",").map(Number) : value;

    setvendorTypeName(updatedValue as number[]);
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      vendorTypeIds: updatedValue as number[],
    }));
    validateForm();
  };

  const handleChangeForPolicyNumber = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormDetails({ ...formDetails, [name]: value });
  };

  //Handle save contact data of vendor
  const handleSaveContact = (contact: IContactDetails) => {
    setContactDetails((prevContacts) => [...prevContacts, contact]);
    setFormDetails((prevFormDetails) => ({
      ...prevFormDetails,
      contact: [...prevFormDetails.contact, contact],
    }));
  };

  //Handles when cancel button is clicked
  const handleCancel = () => {
    navigate("/vendors", { replace: true });
  };

  //Handles Create and Updated data of vendor
  const createVendor = async (vendorDeatils: IVendor) => {
    
    const formData = new FormData();
    formData.append("companyName", vendorDeatils.companyName);
    formData.append("policyNumber", vendorDeatils.policyNumber?.toString());
    formData.append("hasW9", vendorDeatils.hasW9.toString());
    formData.append(
      "coiStartDate",
      vendorDeatils.coiStartDate
        ? vendorDeatils.coiStartDate.format("YYYY-MM-DD")
        : ""
    );
    formData.append(
      "coiExpiryDate",
      vendorDeatils.coiExpiryDate
        ? vendorDeatils.coiExpiryDate.format("YYYY-MM-DD")
        : ""
    );
    formData.append("companyURL", vendorDeatils.companyURL);
    formData.append("note", vendorDeatils.vendorNote);
    formData.append("userId", userId);
    formData.append("vendorId", vendorDeatils.vendorId.toString());
    formData.append("customerId", customerId.toString());
    // Append VendorType Id's
    vendorDeatils.vendorTypeIds.forEach((vendorTypeId: number) => {
      formData.append("vendorTypeIds", vendorTypeId.toString());
    });
    // Append Contact
    contact.forEach((contact, index) => {
      formData.append(`contact[${index}].firstName`, contact.firstName);
      formData.append(`contact[${index}].lastName`, contact.lastName);
      formData.append(`contact[${index}].email`, contact.email);
      formData.append(`contact[${index}].contactNo`, contact.contactNo);
      formData.append(
        `contact[${index}].alternatePhoneNumber`,
        contact.alternatePhoneNumber
      );
      formData.append(
        `contact[${index}].canReceiveEmail`,
        contact.canReceiveEmail.toString()
      );
      formData.append(
        `contact[${index}].canReceiveSms`,
        contact.canReceiveSms.toString()
      );
      formData.append(`contact[${index}].id`, contact.contactId);
      formData.append(
        `contact[${index}].isDeleted`,
        contact.isDeleted.toString()
      );
      formData.append(
        `contact[${index}].sendSmsToPhone`,
        contact.sendSmsToPhone
      );
    });
    // Append Address
    formData.append("address.address1", vendorDeatils.address1);
    formData.append("address.address2", vendorDeatils.address2);
    formData.append("address.addressId", vendorDeatils.addressId.toString());
    formData.append("address.isActive", "true");
    formData.append("address.city", vendorDeatils.city);
    formData.append("address.stateId", vendorDeatils.stateId.toString());
    formData.append("address.zipCode", vendorDeatils.zipCode);

    vendorDeatils.files
      .filter(file => !file.attachmentId) // Filter out the ones that already have an attachmentId
      .forEach((file, index) => {
        // Append only the files that do not have an attachmentId
        formData.append(`DocumentFiles[${index}].Documents`, file.documents); // Assuming documents is an object
        formData.append(`DocumentFiles[${index}].EntityTypeId`, file.entityTypeId.toString());
      });

    try {
      let response;
      if (vendorId) {
        response = await vendorServices.UpdateVendorAsync(formData);
      } else {
        response = await vendorServices.CreateVendorAsync(formData);
      }
      return response;
    } catch (error: any) {
      setError(error.response.data.errors.join(", "));
    }
  };
  const validateForm = () => {

    const newErrors = {
      companyName: formDetails.companyName === "",
      address1: formDetails.address1 === "",
      state: formDetails.stateId === "",
      city: formDetails.city === "",
      zipCode: formDetails.zipCode === "",
      vendorTypeId: !formDetails.vendorTypeIds || !formDetails.vendorTypeIds.length,
      // Only check for coiStartDate and coiEndDate if vendorTypeId is 1
      coiStartDate: formDetails.vendorTypeIds.includes(1) && formDetails.coiStartDate === null,
      coiEndDate: formDetails.vendorTypeIds.includes(1) && formDetails.coiExpiryDate === null,
    };
    // Separate flag for date validation
    let dateError = false;

    // Validate COI dates only if vendorTypeIds includes 1 and both dates are provided
    if (formDetails.vendorTypeIds.includes(1) && formDetails.coiStartDate && formDetails.coiExpiryDate) {
      const startDate = dayjs(formDetails.coiStartDate);
      const endDate = dayjs(formDetails.coiExpiryDate);
      if (startDate.isAfter(endDate)) {
        dateError = true;
      }
    }
    setDateValidationError(dateError);
    setErrors(newErrors);

    return !Object.values(newErrors).some(Boolean) && !dateError;
  };

  //Handle the form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (validateForm()) {
      setSavingData(true);
      e.preventDefault();
      const submitter = (e.nativeEvent as SubmitEvent)
        .submitter as HTMLButtonElement;
      const buttonType = submitter.name;
      var userDetailsObj: any = localStorage.getItem("userDetails");
      const userDetails = decryptData(userDetailsObj);
      const retrievedUserId = userDetails.userId;
      const formDetailsWithUserId = {
        ...formDetails,
        userId: retrievedUserId,
      };
      const errors = validateVendor(formDetailsWithUserId);
      setValidationErrors(errors);
      if (Object.keys(errors).length === 0) {
        try {
          const response = await createVendor(formDetailsWithUserId);
          if (response?.isSuccess) {
            if (vendorId) {
              // Update scenario
              setSnackbarMessage("Vendor updated successfully.");
              setOpenSnackbar(true);
            } else {
              // Create scenario
              setSnackbarMessage("Vendor saved successfully.");
              setOpenSnackbar(true);
            }
            if (buttonType == "SaveVendor") {
              // setSnackbarMessage("Vendor saved successfully.");
              setLoaderIsVisible(false);
              setOpenSnackbar(true);
              setTimeout(() => {
                navigate("/vendors");
              }, 1000);
              setFormDetails(initialVendorState);
              setTouchedFields({});
              setSavingData(false);
            } else if (buttonType === "Save_Add") {
              setTouchedFields({}); // Reset touched fields
              setLoaderIsVisible(false);
              // setSnackbarMessage("Vendor saved successfully.");
              setOpenSnackbar(true);
              setFormDetails(initialVendorState);
              setContactDetails([]);
              // Use setTimeout to ensure the snackbar is displayed before navigating and refreshing
              setTimeout(() => {
                navigate("/add-vendor");
              }, 500);
            }
          }
        } catch (error: any) {
          if (error.response?.data.errors[0] == "Vendor Already Exists") {
            setValidationErrors({
              ...validationErrors,
            });
            setSavingData(false);
          } else {
            setError(error.response.data.errors.join(", "));
            setSavingData(false);
          }
        }
      }
      setSavingData(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };
  const handleDeleteConfirm = async () => {
    if (vendorId !== null && deleteRequest !== null) {
      try {
        setDeleteDialogOpen(false);
        const response = await vendorServices.DeleteVendorAsync(
          deleteRequest.vendorId,
          deleteRequest.userId
        );
        if (response.isSuccess) {
          setSnackbarMessage("Vendor deleted successfully");
          setOpenSnackbar(true);
          setTimeout(() => {
            navigate("/vendors");
          }, 3000);
        }
        setDeleteDialogOpen(false);
      } catch (error: any) {
        setError(error.response.data.errors.join(", "));
      }
    }
  };

  useEffect(() => {
    // Fetch vendorType data
    const getVendorTypeData = async () => {
      try {
        const data = await vendorServices.getVendorList();
        if (data.isSuccess) {
          setvendorTypeList(data.vendorTypes);
        }
      } catch (error: any) {
        setError(error.response.data.errors.join(", "));
      }
    };

    //To get the Countries and States data
    const getCountryStateData = async () => {
      try {
        const data = await billingService.fetchCountryStateData();
        if (data) {
          setCountries(data.country);
          setStates(data.state);
        }
      } catch (error: any) {
        setErrors(error.response.data.errors.join(", "));
      }
    };

    //fetch the VendorData
    const fetchVendorData = async () => {
      if (vendorId) {
        setEditVendorTab("true");
        try {
          const data = await vendorServices.getVendorById(vendorId);
          setTimeout(() => {
            data.coiStartDate = data.coiStartDate
              ? dayjs(data.coiStartDate)
              : null;
            data.coiExpiryDate = data.coiExpiryDate
              ? dayjs(data.coiExpiryDate)
              : null;
            const mappedData: IVendor = {
              userId: data.userId || "",
              companyName: data.companyName || "",
              companyURL: data.companyURL || "",
              vendorTypeIds: data.vendorTypeList.map(
                (type: any) => type.vendorTypeId
              ),

              contact: data.contact || [],
              vendorId: data.vendorId,
              customerId: data.customerId,
              address1: data.address.address1 || "",
              address2: data.address.address2 || "",
              isActive: data.address.isActive,
              city: data.address.city || "",
              stateId: data.address.stateId,
              zipCode: data.address.zipCode || "",
              addressId: data.address.addressId,
              vendorNote: data.vendorNote.notes || "",
              hasW9: data.hasW9,
              coiExpiryDate: data.coiExpiryDate || "",
              coiStartDate: data.coiStartDate || "",
              files: [],
              policyNumber: data.policyNumber
            };
            // Populate existing file lists based on attachment types
            data.attachmentList.forEach((attachment: any) => {
              // Push each attachment into the tempFile array with the corresponding entityType
              mappedData.files.push({
                ...attachment,
                documents: new File([], attachment.fileName),  // Use fileName or originalName from the backend response
                entityTypeId: attachment.entityTypeId, // Map entityTypeId to distinguish file types (COI, W9, etc.)
              });
            });

            if (data.hasW9 == true) {
              setIsFirstTimeClickedW9(false);
            }
            setContactDetails(data.contact);
            setAttachmentCount(data.attachmentList.length);
            setFormDetails(mappedData);
          }, 300);
        } catch (error: any) {
          console.error("Error fetching vendor data:", error);
        }
      }
    };
    getCountryStateData();
    getVendorTypeData();
    fetchVendorData();
  }, []);

  // Handle date changes for date pickers
  const handleDateChange = (name: keyof IVendor, date: Dayjs | null) => {
    if (date && date.isValid()) {
      const formattedDate = date.format("YYYY-MM-DD");
      setFormDetails((prevState) => ({
        ...prevState,
        [name]: formattedDate,
      }));
    } else {
      setFormDetails((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  // Handle checkbox change
  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      hasW9: isChecked,
    }));
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<{ uri: string, fileName: string, fileType: string } | null>(null);

  const [showDocument, setShowDocument] = useState<boolean>(false);

  const handleFileClick = (params: any) => {

    setDrawerOpen(true);
    let fileType;

    if (params.url) {
      // If the file has a URL (from saved files), extract the file type from the URL
      fileType = params.url.split(".").pop().split("?")[0].toLowerCase();
      const document = {
        uri: params.url, // Saved file URL
        fileName: params.fileName,
        fileType,
      };
      setSelectedFile(document);
      setShowDocument(true);
    } else if (params.documents) {
      // If the file is newly added (before saving)
      const file = params.documents;
      const fileType = file.name.split('.').pop().toLowerCase();
      const document = {
        uri: URL.createObjectURL(file),
        fileName: file.name,
        fileType
      };
      setSelectedFile(document);
      setShowDocument(true);
    }

    setTimeout(() => {
      setShowDocument(true);
    }, 0);
  };
  const handleFileChangeForDocumnetType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = ['.pdf', '.jpg', '.dwf', '.dwg', '.tif', '.jpeg', '.png'];
    // Check if document type is selected
    if (!documentTypeId) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, files: 'Please select a document type before uploading files.' }));
      return; // Early return if no document type is selected
    }

    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      const validFiles = filesArray.filter((file) => {
        const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;
        return allowedExtensions.includes(fileExtension);
      });

      if (validFiles.length > 0) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, files: '' }));

        validFiles.forEach((file) => {
          formDetails.files.push({ documents: file, entityTypeId: parseInt(documentTypeId) });
        });

      }
      e.target.value = '';
    }
  };

  return (
    <div
      className={
        styles.addVendorContainer +
        " " +
        (editVendorTab === "false" ? " " : styles.addVendorContainer)
      }
    >
      {editVendorTab === "false" && (
        <Grid container className={styles.search_container}>
          <Grid item xs={12}>
            <h3 id="add_vendor_title">{t("AddVendor.add_vendor_title")}</h3>
          </Grid>
        </Grid>
      )}
      {editVendorTab === "true" && (
        <Grid container className={styles.search_container}>
          <Grid
            item
            xs={12}
            className="grid_center"
          >
            <h3 id="edit_vendor_title">{t("AddVendor.edit_vendor_title")}</h3>
            <div className="v_center">
              <IconButton>
                <Badge badgeContent={attachmentCount} color="primary">
                  <CollectionsIcon />
                </Badge>
              </IconButton>
            </div>
          </Grid>
        </Grid>
      )}
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Grid container item sm={5.5} spacing={2}>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                id="companyName"
                name="companyName"
                placeholder={t("AddVendor.companyName")}
                label={t("AddVendor.companyName")}
                value={formDetails.companyName || ""}
                variant="outlined"

                onChange={(e) =>
                  setFormDetails({
                    ...formDetails,
                    companyName: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
                onBlur={() => handleBlur('companyName')}
                error={touchedFields.companyName && errors.companyName}
                helperText={touchedFields.companyName && errors.companyName ? "Company Name is required" : ""}
              />
            </Grid>
            <Grid item xs={12} container className="fieldWrapper">
              <TextField
                id="address1"
                name="address1"
                value={formDetails.address1 || ""}
                placeholder="Address"
                label={t("AddVendor.address1")}
                variant="outlined"
                className="mb20"

                onChange={(e) =>
                  setFormDetails({ ...formDetails, address1: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                onBlur={() => handleBlur('address1')}
                error={touchedFields.address1 && errors.address1}
                helperText={touchedFields.address1 && errors.address1 ? "Address 1 is required" : ""}
              />
              <TextField
                id="address2"
                name="address2"
                value={formDetails.address2 || ""}
                placeholder="Address"
                label={t("AddVendor.address2")}
                variant="outlined"
                onChange={(e) =>
                  setFormDetails({ ...formDetails, address2: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid
              item
              sm={12}
              className="fieldWrapper flex_columns_in_mobile"
              alignItems="start"
            >
              <TextField
                id="city"
                name="city"
                value={formDetails.city || ""}
                placeholder={t("AddVendor.city")}
                label={t("AddVendor.city")}
                variant="outlined"
                className="inlineSelect oneThird"
                onChange={(e) =>
                  setFormDetails({ ...formDetails, city: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                onBlur={() => handleBlur('city')}
                error={touchedFields.city && errors.city}
                helperText={touchedFields.city && errors.city ? "City is required" : ""}
              />
              <FormControl className="inlineSelect oneThird">
                <Autocomplete
                  size="small"
                  id="state-autocomplete"
                  options={states.filter(
                    (state) => state.countryId === Number(233)
                  )} // Filter states by countryId
                  value={
                    states.find(
                      (state) => state.stateId === formDetails.stateId
                    ) || null
                  } // Set value based on stateId
                  onChange={(event, newValue) => {
                    setFormDetails((prevDetails) => ({
                      ...prevDetails,
                      stateId: newValue ? newValue.stateId : "", // Update stateId based on selected state
                    }));
                  }}
                  getOptionLabel={(option) => option.stateName || ""} // Display state names in the dropdown
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("AddVendor.state-autocomplete")}
                      variant="outlined"
                      placeholder={t("AddVendor.state-autocomplete")}
                      onBlur={() => handleBlur('state')}
                      error={touchedFields.state && errors.state}
                      helperText={touchedFields.state && errors.state ? "State is required" : ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />
              </FormControl>
              <TextField
                id="zipCode"
                value={formDetails.zipCode || ""}
                type="text"
                placeholder={t("AddVendor.zipCode")}
                label={t("AddVendor.zipCode")}
                name="zipcode"
                className="oneThird"
                variant="outlined"
                onBlur={() => handleBlur('zipCode')}
                error={touchedFields.zipCode && errors.zipCode}
                helperText={touchedFields.zipCode && errors.zipCode ? "Zip code is required" : ""}
                onChange={(e) =>
                  setFormDetails({ ...formDetails, zipCode: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },  
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
              />
            </Grid>
            {/* Conditionally render COI fields if Vendor Type is "Sub Contractor" */}
            {formDetails.vendorTypeIds.includes(1) && (
              <>
                <Grid item sm={6} container className="fieldWrapper">
                  <FormControl error={touchedFields.coiStartDate && errors.coiStartDate}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format={process.env.REACT_APP_DATE_FORMAT}
                        label="COI Start Date"
                        value={formDetails.coiStartDate || null}

                        onChange={(date: Dayjs | null) =>
                          setFormDetails((prevState) => ({
                            ...prevState,
                            coiStartDate: date,
                          }))
                        }
                        onAccept={() => handleBlur('coiStartDate')}
                        onClose={() => handleBlur('coiStartDate')}
                        slotProps={{
                          textField: {
                            InputLabelProps: { shrink: true }
                            , error: (touchedFields.coiStartDate && !!errors.coiStartDate),
                          },
                        }}

                      />
                    </LocalizationProvider>
                    {touchedFields.coiStartDate && errors.coiStartDate && (
                      <FormHelperText>CoiStartDate is required</FormHelperText>
                    )}
                    {dateValidationError && (
                      <FormHelperText error>
                        "COI Start date cannot be after COI Expiry date"
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} container className="fieldWrapper">
                  <FormControl error={touchedFields.coiEndDate && errors.coiEndDate}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format={process.env.REACT_APP_DATE_FORMAT}
                        label="COI Expiry Date"
                        value={formDetails.coiExpiryDate || null}
                        onAccept={() => handleBlur('coiEndDate')}  // Trigger touch handling when the user selects a date
                        onClose={() => handleBlur('coiEndDate')}
                        slotProps={{
                          textField: {
                            InputLabelProps: { shrink: true },
                            error: (touchedFields.coiEndDate && !!errors.coiEndDate),
                          },

                        }}
                        onChange={(date: Dayjs | null) =>
                          setFormDetails((prevState) => ({
                            ...prevState,
                            coiExpiryDate: date,
                          }))
                        }
                      />
                    </LocalizationProvider>
                    {touchedFields.coiEndDate && errors.coiEndDate && (
                      <FormHelperText>COI End Date is required</FormHelperText> // Display helper text
                    )}
                    {dateValidationError && (
                      <FormHelperText error>
                        "COI Expiry date cannot be before COI Start date"
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} className="fieldWrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formDetails.hasW9}
                    onChange={handleCheckBox}
                    color="primary"
                  />
                }
                label="W9"
              />
            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                fullWidth
                type="PolicyNumber"
                id="policy_number"
                placeholder={t("AddVendor.policy_number")}
                label={t("AddVendor.policy_number")}
                name="policyNumber"
                value={formDetails.policyNumber || ""}
                variant="outlined"
                onChange={handleChangeForPolicyNumber}
                InputProps={{
                  type: "string",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid item container sm={5.5} spacing={2}>
            <Grid item xs={12} className="fieldWrapper">
              <FormControl
                className="inlineSelect"
                error={touchedFields.vendorTypeId && errors.vendorTypeId}
              >
                <InputLabel id="type_placeholder" shrink={true}>
                  {t("AddVendor.type_placeholder")}
                </InputLabel>
                <Select
                  labelId="type_placeholder"
                  id="vendorTypeId"
                  placeholder="Type"
                  className="selectBox"
                  label="Vendor Type"
                  multiple
                  name="vendorTypeId"
                  value={formDetails.vendorTypeIds}
                  onChange={handleVendorTypeChange}
                  input={<OutlinedInput notched label="Vendor Type" />}
                  renderValue={(selected) =>
                    selected
                      .map((value) => {
                        const vendorType = vendorTypesList.find(
                          (lookup) => lookup.vendorTypeId === value
                        );
                        return vendorType ? vendorType.vendorType : value;
                      })
                      .join(", ")
                  }
                  onBlur={() => handleBlur('vendorTypeId')}
                >
                  {vendorTypesList?.map((lookup) => (
                    <MenuItem
                      key={lookup.vendorTypeId}
                      value={lookup.vendorTypeId}
                    >
                      <Checkbox
                        checked={
                          formDetails.vendorTypeIds.indexOf(
                            lookup.vendorTypeId
                          ) > -1
                        }
                      />
                      <ListItemText primary={lookup.vendorType} />
                    </MenuItem>
                  ))}
                </Select>
                {touchedFields.vendorTypeId && errors.vendorTypeId && (
                  <FormHelperText>VendorType is required</FormHelperText> // Display helper text
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                id="companyURL"
                name="companyURL"
                placeholder="URL"
                label={t("AddVendor.url")}
                value={formDetails.companyURL || ""}
                variant="outlined"
                onChange={(e) =>
                  setFormDetails({ ...formDetails, companyURL: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                id="notes"
                name="note"
                value={formDetails.vendorNote || ""}
                placeholder={t("AddVendor.notes_placeholder")}
                label={t("AddVendor.notes")}
                variant="outlined"
                onChange={(e) =>
                  setFormDetails({
                    ...formDetails,
                    vendorNote: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* document type sector input */}
            <Grid item xs={12} className="fieldWrapper">
              <FormControl
                className="inlineSelect"
                error={!!validationErrors.vendorTypeIds || !!validationErrors.files}
              >
                <InputLabel id="doc_type_placeholder" shrink={true}>
                  {t("AddVendor.doc_type_placeholder")}
                </InputLabel>
                <Select
                  labelId="doc_type_placeholder"
                  id="documentType"
                  placeholder="Type"
                  className="selectBox"
                  label="Document Type"
                  name="documentTypeId"
                  onChange={handleDocumentTypeChange}
                  value={documentTypeId}
                  input={<OutlinedInput notched label="Document Type" />}
                >
                  {formDetails.vendorTypeIds.includes(1) && (
                    <MenuItem value={EntityType.COI}>COI</MenuItem>
                  )}
                  {formDetails.hasW9 && (
                    <MenuItem value={EntityType.W9}>W9</MenuItem>
                  )}
                  {formDetails.policyNumber != "" ? (
                    <MenuItem value={EntityType.policyNumber}>Policy</MenuItem>
                  ) : null}
                  <MenuItem value={EntityType.vendor}>Other</MenuItem>
                </Select>
                <FormHelperText>{validationErrors.files}</FormHelperText>
              </FormControl>

            </Grid>

            <Grid item xs={12} className="fieldWrapper">
              <FormControl error={!!validationErrors.files}>
                {/* <InputLabel id="upload_placeholder">Upload Files</InputLabel> */}
                <Button
                  component="label"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  aria-label="Upload Files"
                  aria-placeholder={t("AddVendor.upload_btn")}
                  id="upload_btn"
                  endIcon={
                    <Icon
                      className="uploadButtonIcon"
                      path={mdiCloudUpload}
                      size={1}
                    />
                  }
                  className={`uploadButton ${validationErrors.fileType ? "error" : ""
                    }`}
                >
                  {t("AddVendor.upload_btn")}
                  <input
                    type="file"
                    multiple
                    //ref={fileRef}
                    accept=".pdf,.jpg,.dwf,.dwg,.tif,.jpeg,.png"
                    className="d_none"
                    onChange={handleFileChangeForDocumnetType}
                  />
                </Button>

              </FormControl>
            </Grid>

            {/* showing upladed files */}

            <Grid item xs={12} container className="fileListContainer fileListMax">
              <div className="fileListMax">
                {formDetails.vendorTypeIds.includes(1) && (
                  <>
                    <Divider sx={{ width: "100%" }}>
                      <Chip label="COI Files" size="small" />
                    </Divider>

                    <div>
                      {formDetails.files
                        ?.filter((file) => file.entityTypeId === EntityType.COI).map((file, index) => (
                          <div key={index} className="fileItem" onClick={() => handleFileClick(file)}>
                            <span>{file.documents.name}</span>
                            <svg
                              className="cancelIcon"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent drawer from opening
                                handleRemoveFileClick(file)
                              }}
                            >
                              <HighlightOffIcon />
                            </svg>
                          </div>
                        ))}
                    </div>
                  </>
                )}
                {formDetails.hasW9 && (
                  <>
                    <Divider sx={{ width: "100%" }}>
                      <Chip label="W9 Files" size="small" />
                    </Divider>
                    <div>
                      {formDetails.files
                        ?.filter((file) => file.entityTypeId === EntityType.W9).map((file, index) => (
                          <div key={index} className="fileItem" onClick={() => handleFileClick(file)}>
                            <span>{file.documents.name}</span>
                            <svg
                              className="cancelIcon"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveFileClick(file)
                              }}
                            >
                              <HighlightOffIcon />
                            </svg>
                          </div>
                        ))}
                    </div>
                  </>
                )}
                {formDetails.policyNumber != "" ? (
                  <>
                    <Divider sx={{ width: "100%" }}>
                      <Chip label="Policy Files" size="small" />
                    </Divider>
                    <div>
                      {formDetails.files
                        ?.filter((file) => file.entityTypeId === EntityType.policyNumber).map((file, index) => (
                          <div key={index} className="fileItem" onClick={() => handleFileClick(file)}>
                            <span>{file.documents.name}</span>
                            <svg
                              className="cancelIcon"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveFileClick(file)
                              }}
                            >
                              <HighlightOffIcon />
                            </svg>
                          </div>
                        ))}
                    </div>
                  </>
                ) : null}
                <Divider sx={{ width: "100%" }}>
                  <Chip label="Other Files" size="small" />
                </Divider>
                <div>
                  {formDetails.files
                    ?.filter((file) => file.entityTypeId === EntityType.vendor).map((file, index) => (
                      <div key={index} className="fileItem" onClick={() => handleFileClick(file)}>
                        <span>{file.documents.name}</span>
                        <svg
                          className="cancelIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveFileClick(file)
                          }}
                        >
                          <HighlightOffIcon />
                        </svg>
                      </div>
                    ))}
                </div>


              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid
          item
          xs={12}
          alignItems="flex-center"
          className={styles.top_buttons}
          sx={{ mt: 4 }}
        >
          <h4 id="contact_title"> {t("AddVendor.contact_title")} </h4>
          <Button
            variant="contained"
            className="primary-button rounded"
            id="add_contact_btn"
            onClick={handleDrawerOpen}
          >
            {t("AddVendor.add_contact_btn")}
          </Button>
        </Grid>
      </Box>
      <Box>
        <VendorContactList
          contactProp={contact}
          onUpdateContacts={handleUpdateContacts}
          vendorId={vendorId}
        ></VendorContactList>
        <AddVendorContact
          vendorId={vendorId}
          open={addContactOpen}
          onClose={handleDrawerClose}
          onSaveContact={handleSaveContact}
          rowIndex={null}
        ></AddVendorContact>
      </Box>
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
        <Grid
          container
          item
          sm={12}
          sx={{ justifyContent: "space-between", gap: 0 }}
          className="submitButtons"
        >
          <Grid item sm={4}>
            {editVendorTab === "true" && (
              <Button
                type="button"
                fullWidth
                variant="outlined"
                className="primary-button widthauto red-button"
                onClick={() => handleDelete(vendorId)}
              >
                Delete
              </Button>
            )}
          </Grid>
          <Grid
            item
            sm={8}
            className="submitButtons"
            sx={{ mt: "0 !important" }}
          >
            <Button
              type="button"
              fullWidth
              variant="outlined"
              className="outline-button widthauto"
              onClick={handleCancel}
            >
              {t("AddVendor.cancel_btn")}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="primary-button widthauto"
              name="SaveVendor"
              disabled={!isFormValid || savingData}
              startIcon={
                savingData ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              }
            >
              {savingData ? t("AddVendor.saving_btn") : t("AddVendor.save_btn")}{" "}
            </Button>
            {editVendorTab === "false" && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="primary-button widthauto"
                name="Save_Add"
                disabled={!isFormValid}
              >
                {t("AddVendor.save_add_btn")}
              </Button>
            )}
          </Grid>
        </Grid>
        {deleteDialogOpen && (
          <DeleteDialog
            open={deleteDialogOpen}
            title={deleteDialogTitle}
            cancelbuttonText={dialogCancelButtonText}
            submitButtonText={dialogDeleteButtonText}
            cancelbuttonLink={handleDeleteCancel}
            submitButtonLink={handleDeleteConfirm}
          />
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
      <DocumentViewerVendor
        open={drawerOpen}
        selectedDocument={selectedFile ? [selectedFile] : []}
        showDocument={showDocument}
        closePreview={() => setDrawerOpen(false)}
      />
    </div>
  );
};
export default AddVendor;
