import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Button,
  IconButton,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  SelectChangeEvent,
  FormHelperText,
  FormControl,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  styled,
  Autocomplete,
} from "@mui/material";
import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import styles from "./Billing-Information.module.scss";
import { validateBillingInformation } from "../../utils/validation";
import {
  IBillingInformation,
  IBillingInformationErrors,
  ICountry,
  IState,
  IBillingInformationProps,
  IProductData,
} from "../../interfaces";
import { billingService } from "../../api";
import { ISubcriptionDetails } from "../../interfaces";
import { decryptData } from "../../utils/CryptoJS";
import { BillingInformationDialog } from "../../containers";
import { useBannerContext } from "../../utils/BannerContext";
import { useTranslation } from "react-i18next";
interface BillingInformationProps {
  fullHeight?: boolean;
  onCancel?: () => void; // Make this optional
}
export const BillingInformationToggle = styled(ToggleButtonGroup)(() => ({
  "& .MuiToggleButton-root": {
    padding: "12px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    border: "1px solid #EEE !important",
    background: "#FFF",
  },
  "& .MuiToggleButton-root:hover, & .Mui-selected": {
    background: "var(--primary) !important",
    color: "#FFF !important",
    borderRadius: "4px !important",
    border: "1px solid var(--primary) !important",
  },
  "& .inputGroup .MuiInputBase-root": {
    border: "0px",
  },
  "& .MuiRadioGroup-root label": {
    width: "100%",
    h3: {
      fontSize: "14px",
      fontWeight: "700",
      marginBottom: "0px",
      lineHeight: "1",
    },
  },
}));

const BillingInformation: React.FC<BillingInformationProps> = ({ fullHeight = false, onCancel }) => {
  const { setShowBanner } = useBannerContext();
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [states, setStates] = useState<IState[]>([]);
  const [selectedCountryId, setSelectedCountryId] = useState<number | null>(
    null
  );
  const [selectedStateId, setSelectedStateId] = useState<IState>();
  const {t} = useTranslation();
  const BillingObj: IBillingInformation = {
    cardNumber: "",
    expiryDate: "",
    securityCode: "",
    users: 1,
    planTerm: "monthly",
    billingAddress: "",
    total: 0,
    address1: "",
    address2: "",
    stateId: "",
    countryId: 233,
    city: "",
    zipCode: "",
  };
  const [formDetails, setFormDetails] = useState({ ...BillingObj });
  const [errors, setErrors] = useState<Partial<IBillingInformationErrors>>({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [userInputValue, setUserInputValue] = useState<string>("1");
  const [planTerm, setPlanTerm] = useState("monthly");
  const [productData, setProductData] = useState<IProductData[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<number>(2);
  const [total, setTotal] = useState<number>(1);
  const [planValue, setPlanValue] = React.useState("payPerProject");

  const handleChangePlan = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Handles the change event for the plan radio button field.
    setPlanValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    const getCountryStateData = async () => {
      try {
        const data = await billingService.fetchCountryStateData();
        if (data) {
          setCountries(data.country);
          setStates(data.state);
        }
      } catch (error) {
        console.error("Error fetching country and state data", error);
      }
    };
    const getProductData = async () => {
      try {
        const data = await billingService.getProducts();

        if (data) {
          setProductData(data.product);
        }
      } catch (error) {
        console.error("Error fetching Product data", error);
      }
    };

    getCountryStateData();
    getProductData();
  }, []);

  const calculateTotal = (selectedProductId: number, users: number) => {
    const product = productData.find((p) => p.productId === selectedProductId);
    if (product && users) {
      return product.unitPrice * users;
    }
    return 0;
  };

  useEffect(() => {
    setTotal(calculateTotal(selectedProductId, parseInt(userInputValue)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductId, userInputValue, productData]);

  const updateShowBanner = () => {
    setShowBanner(false); // Set showBanner to false
  };
  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const combinedBillingAddress = `${formDetails.address1} ${formDetails.address2}`;
    const updatedFormDetails = {
      ...formDetails,
      total: total,
      billingAddress: combinedBillingAddress,
      users: parseInt(userInputValue),
    };
    const validationErrors = validateBillingInformation(updatedFormDetails,t);
    setErrors(validationErrors);

    try {
      if (Object.keys(validationErrors).length === 0) {
        setIsSubmitDisabled(false);
        var userDetails: any = localStorage.getItem("userDetails");
        var userDetailsObj = decryptData(userDetails);

        //call the api
        try {
          const subscriptionDetails: ISubcriptionDetails = {
            productId: formDetails.planTerm === "monthly" ? 2 : 3,
            userCount: parseInt(userInputValue),
            billTotal: total,
            userId: userDetailsObj.userId,
            countryId: selectedCountryId ? selectedCountryId : 233,
            stateId: selectedStateId ? selectedStateId.stateId : "",
            zipCode: formDetails.zipCode,
            address1: formDetails.address1,
            address2: formDetails.address2,
            city: formDetails.city,
            isActive: true,
          };

          const result = await billingService.purchaseSubscription(
            subscriptionDetails
          );
          if (!result.isSuccess) {
            setIsSubmitDisabled(true);
          } else {
            updateShowBanner();
            //onClose();
          }
        } catch (error: any) {
          console.error("Error:", error);
        }
      } else {
        setIsSubmitDisabled(true);
      }
    } catch (error: any) {
      setIsSubmitDisabled(true);
      console.error("Error", error);
    }
  };

  // const handleCountryChange = (event: SelectChangeEvent<Number>) => {
  //   const selectedCountryId = event.target.value;
  //   if (typeof selectedCountryId === "number") {
  //     setSelectedCountryId(selectedCountryId);
  //   }
  //   setFormDetails({
  //     ...formDetails,
  //     countryId: selectedCountryId,
  //   });
  //   setIsSubmitDisabled(false);
  // };

  const handleStateChange = (event: any, newValue: any) => {
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      stateId: newValue ? newValue.stateId.toString() : "", // Update stateId based on selected state
    }));
    setSelectedStateId(newValue);
    console.log(newValue ? newValue.stateId.toString() : "");
    setIsSubmitDisabled(false);
  };

  const handleUserIncrement = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!userInputValue) {
      setUserInputValue("0");
    }
    const userInputValueAfterAddition = parseInt(userInputValue) + 1;
    if (userInputValueAfterAddition > 99) {
      setErrors({
        ...errors,
        users: "User count should be less than 100",
      });
      return;
    } else {
      setErrors({
        ...errors,
        users: "",
      });
    }
    setUserInputValue(userInputValueAfterAddition.toString());
  };

  const handleUserDecrement = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!userInputValue) {
      setUserInputValue("0");
    }
    const userInputValueAfterSubtraction = parseInt(userInputValue) - 1;

    if (userInputValueAfterSubtraction < 1) {
      setErrors({
        ...errors,
        users: "User count should be greater than 1.",
      });
      return;
    } else {
      setErrors({
        ...errors,
        users: "",
      });
    }
    setUserInputValue(userInputValueAfterSubtraction.toString());
  };

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event?.target?.value;
    if (!value) {
      setErrors({
        ...errors,
        users: "Please enter a valid user count.",
      });
      setUserInputValue("");
      return;
    }
    const numericValue = parseInt(value);
    if (numericValue > 99 || numericValue < 1 || !/^\d*$/.test(value)) {
      setErrors({
        ...errors,
        users: "User count must be between 1 to 99.",
      });
      return;
    }
    setUserInputValue(value);
    setErrors({
      ...errors,
      users: "",
    });
  };

  const handlePlanTermChange = (
    event: React.MouseEvent<HTMLElement>,
    newPlanTerm: string
  ) => {
    event.preventDefault();
    if (newPlanTerm !== null) {
      setSelectedProductId(newPlanTerm === "monthly" ? 2 : 3);
    }
    setPlanTerm(newPlanTerm);
    setFormDetails({
      ...formDetails,
      planTerm: newPlanTerm,
    });
    setIsSubmitDisabled(false);
    if (newPlanTerm) {
      setErrors({
        ...errors,
        planTerm: "",
      });
    }
  };

  const handleInputChange = (
    field: keyof IBillingInformation,
    value: string
  ) => {
    setFormDetails({ ...formDetails, [field]: value });
  };
  const handleClose = () => {
    setFormDetails(BillingObj);
    setSelectedProductId(2);
    setSelectedCountryId(null);
    setSelectedStateId(undefined);
    setErrors({});
    setUserInputValue("1");
    // if (onClose) {
    //   onClose();
    // }
  };
  const handleBlur = (fieldName: keyof IBillingInformation) => {
    if (!userInputValue) {
      setUserInputValue("1");
    }
    const newErrors = validateBillingInformation({
      ...formDetails,
      [fieldName]: formDetails[fieldName],
    },t);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: newErrors[fieldName],
    }));
  };

  const { showBanner } = useBannerContext();

  return (
    <div
      className={`${fullHeight ? styles.fullHeight : styles.addProjectcontainer} +  content_with_margin + ${
        showBanner ? styles.showBanner : ""
      }`}
    >
      <form onSubmit={handleSave} className={styles.billing_detail_dialog}>
        <Grid
          container
          className={styles.wrapper_container_inner}
          alignItems="flex-start"
        >
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid container item sm={fullHeight?12:5.5}>
              <Grid item sm={12} className={styles.additional_info_title}>
                <h4 id="card_info_title">{t("Billing-information.card_info_title")}</h4>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} container className="fieldWrapper">
                  <TextField
                    id="card_number"
                    placeholder={t("Billing-information.card_number")}
                    variant="outlined"
                    name="cardName"
                    label={t("Billing-information.card_number")}
                    value={formDetails.cardNumber}
                    onChange={(e) =>
                      handleInputChange("cardNumber", e.target.value)
                    }
                    onBlur={() => handleBlur("cardNumber")}
                    error={!!errors.cardNumber}
                    helperText={
                      errors.cardNumber && (
                        <span className={styles.errorText}>
                          {errors.cardNumber}
                        </span>
                      )
                    }
                  />
                </Grid>
                <Grid item spacing={2} container className="fieldWrapper">
                  <Grid item xs={7}>
                    <TextField
                    id="expiry_date"
                      placeholder={t("Billing-information.expiry_date")}
                      variant="outlined"
                      value={formDetails.expiryDate}
                      label={t("Billing-information.expiry_date")}
                      onChange={(e) =>
                        handleInputChange("expiryDate", e.target.value)
                      }
                      onBlur={() => handleBlur("expiryDate")}
                      error={!!errors.expiryDate}
                      helperText={
                        errors.expiryDate && (
                          <span className={styles.errorText}>
                            {errors.expiryDate}
                          </span>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                    id="security_code"
                      placeholder={t("Billing-information.security_code")}
                      label={t("Billing-information.security_code")}
                      variant="outlined"
                      value={formDetails.securityCode}
                      onChange={(e) =>
                        handleInputChange("securityCode", e.target.value)
                      }
                      onBlur={() => handleBlur("securityCode")}
                      error={!!errors.securityCode}
                      helperText={
                        errors.securityCode && (
                          <span className={styles.errorText}>
                            {errors.securityCode}
                          </span>
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Grid 
                   item
                  container
                  className={styles.payment_wrapper + " fieldWrapper"}>
 <Grid
                  item
                  container
                  className={styles.payment_wrapper + " fieldWrapper"}
                  justifyContent={"space-between"}
                >
                  <FormControl>
                    <RadioGroup
                      row
                      value={planValue}
                      onChange={handleChangePlan}
                    >
                      <FormControlLabel
                        value="payPerProject"
                        control={<Radio />}
                        label={
                          <span className={styles.radioLabel}>
                            <h3 id="pay_per_project_label"> {t("Billing-information.pay_per_project_label")}</h3>
                            <i id="pay_project_info">
                            {t("Billing-information.pay_project_info")}
                            </i>
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="annualSubscription"
                        control={<Radio />}
                        label={
                          <span className={styles.radioLabel}>
                            <h3 id="annual_subscription_label"> {t("Billing-information.annual_subscription_label")} </h3>
                            <i id="annual_info"> {t("Billing-information.annual_info")} </i>
                          </span>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {planValue === "annualSubscription" && (
                  <Grid
                    item
                    container
                    spacing={2}
                    className={styles.internal_row + " fieldWrapper"}
                    justifyContent={"space-between"}
                  >
                    <Grid
                      item
                      xs={7}
                      flexDirection={"column"}
                      alignItems={"start !important"}
                    >
                      <h4 id="pro_plan_label">{t("Billing-information.pro_plan_label")}</h4>
                      <p id="bill_label">{t("Billing-information.bill_label")} {formDetails.planTerm}</p>
                    </Grid>
                    <Grid item xs={5} alignItems={"end"}>
                      <BillingInformationToggle
                        color="primary"
                        value={formDetails.planTerm}
                        exclusive
                        onChange={handlePlanTermChange}
                        aria-label="Platform"
                        className={styles.toggleButton}
                        defaultValue="monthly"
                        size="small"
                      >
                        <ToggleButton className={styles.toggle_button} value="monthly">Monthly</ToggleButton>
                        <ToggleButton className={styles.toggle_button} value="yearly">Yearly</ToggleButton>
                      </BillingInformationToggle>

                      {errors.planTerm && (
                        <p className={styles.errorText}>{errors.planTerm}</p>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  className={styles.internal_row + " fieldWrapper"}
                  justifyContent="space-between"
                  xs={12}
                >
                  <label
                    id="status_placeholder"
                    htmlFor="name"
                    className={styles.smallLabel}
                  >
                    {t("Billing-information.user")}
                  </label>
                  <div className={styles.inputGroupContainer}>
                    <div className={styles.inputGroup}>
                      <Button
                        aria-label="decrement"
                        className={styles.usersInputButton}
                        onClick={handleUserDecrement}
                      >
                        -
                      </Button>
                      <TextField
                        variant="outlined"
                        value={userInputValue}
                        onChange={handleUserChange}
                        onBlur={() => handleBlur("users")}
                        className={styles.usersInput}
                        error={!!errors.users}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 99,
                          },
                        }}
                      />
                      <Button
                        aria-label="increment"
                        className={styles.usersInputButton}
                        onClick={handleUserIncrement}
                      >
                        +
                      </Button>
                    </div>
                    <FormHelperText className={styles.selectboxError}>
                      {errors.users && (
                        <span className={styles.errorText}>{errors.users}</span>
                      )}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid
                  item
                  className={styles.internal_row + " fieldWrapper"}
                  justifyContent="space-between"
                  xs={12}
                >
                  <label
                    id="status_placeholder"
                    htmlFor="name"
                    className={styles.smallLabel}
                  >
                    {t("Billing-information.total")}
                  </label>
                  <div className={styles.totalAmount}>${total} USD</div>
                </Grid>
                </Grid>
               
              </Grid>
            </Grid>
            <Grid container item sm={fullHeight?12:5.5}>
              <Grid item sm={12} className={styles.additional_info_title}>
                <h4 id="billing_address_label">{t("Billing-information.billing_address_label")}</h4>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item container className="fieldWrapper">
                  <TextField
                    placeholder={t("Billing-information.address_placeholder")}
                    label={t("Billing-information.address1")}
                    variant="outlined"
                    value={formDetails.address1}
                    onChange={(e) =>
                      handleInputChange("address1", e.target.value)
                    }
                    onBlur={() => handleBlur("address1")}
                    error={!!errors.address1}
                    helperText={
                      errors.address1 && (
                        <span className={styles.errorText}>
                          {errors.address1}
                        </span>
                      )
                    }
                  />
                </Grid>
                <Grid item container className="fieldWrapper">
                  <TextField
                    variant="outlined"
                    placeholder={t("Billing-information.address_placeholder")}
                    label={t("Billing-information.address2")}
                    value={formDetails.address2}
                    onChange={(e) =>
                      handleInputChange("address2", e.target.value)
                    }
                    onBlur={() => handleBlur("address2")}
                  />
                </Grid>
                <Grid item container spacing={2} className="fieldWrapper">
                  <Grid item xs={4}>
                    <TextField
                      name="city"
                      value={formDetails.city}
                      onChange={(e) =>
                        handleInputChange("city", e.target.value)
                      }
                      placeholder={t("Billing-information.city")}
                      label={t("Billing-information.city")}
                      variant="outlined"
                      className="inlineSelect"
                      error={!!errors.city}
                      helperText={errors.city}
                      onBlur={() => handleBlur("city")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl>
                      <Autocomplete
                        size="small"
                        id="state-autocomplete"
                        options={states.filter(
                          (state) => state.countryId === Number(233)
                        )} // Filter states by countryId
                        value={selectedStateId} // Set value based on stateId
                        onChange={handleStateChange} // Use the separate function
                        getOptionLabel={(option) => option.stateName || ""} // Display state names in the dropdown
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("Billing-information.state")}
                            variant="outlined"
                            placeholder={t("Billing-information.state")}
                            error={!!errors.stateId} // Show error if stateId has an error
                            helperText={errors.stateId} // Display error message if stateId has an error
                            onBlur={() => handleBlur("stateId")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      placeholder={t("Billing-information.zipCode")}
                      label={t("Billing-information.zipCode")}
                      variant="outlined"
                      value={formDetails.zipCode}
                      onChange={(e) =>
                        handleInputChange("zipCode", e.target.value)
                      }
                      onBlur={() => handleBlur("zipCode")}
                      error={!!errors.zipCode}
                      helperText={
                        errors.zipCode && (
                          <span className={styles.errorText}>
                            {errors.zipCode}
                          </span>
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={styles.submmitButton_container}
          spacing={2}
          alignItems="flex-start"
        >
          <Grid container item xs={12} className="submitButtons">
            {fullHeight &&           <Button
                        fullWidth
                        variant="outlined"
                        className="outline-button widthauto"
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>}
            <Button
              type="submit"
              variant="contained"
              className="primary-button"
              disabled={isSubmitDisabled}
              id="confirm_btn"
            >
             {t("Billing-information.confirm_btn")} ${total} USD
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default BillingInformation;
