import React, { useCallback, useEffect, useState } from "react";
import styles from "./users.module.scss";
import Datatable from "../../components/datatable/datatable";
import { Button, colors, Grid, IconButton, InputAdornment, Snackbar, TextField } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { projectService, userService } from "../..//api";
import { decryptData } from "../../utils/CryptoJS";
import { mdiClose, mdiMagnify, mdiMessageProcessing } from "@mdi/js";
import Icon from "@mdi/react";
import AddUsers from "./AddUsers/AddUsers";
import { IUserList } from "../../interfaces/IUser";
import { blue } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const Users: React.FC = () => {

  var customerId = parseInt(localStorage.getItem("customerId") as string, 10);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [count, setCount] = React.useState(0);
  const pageCount = Math.ceil(count / rowsPerPage);
  const [userList, setUserList] = useState<IUserList[]>([]);
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUserList>();
  const selectProject = (ProjectId: any, params: any) => {
    sessionStorage.setItem("ActiveTabIndex", JSON.stringify(0));

    navigate("/project-detail", { state: { projectId: ProjectId } });
  };
  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: t("users.firstName"),
      width: 120,
      flex: 1,
      minWidth: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className="projectLink cursor_pointer">
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "lastName",
      headerName: t("users.lastName"),
      width: 120,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "email",
      headerName: t("users.email"),
      width: 150,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "userType",
      headerName: t("users.userType"),
      width: 150,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "phones",
      headerName: t("users.phones"),
      width: 200,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params) => {
        const {
          contactNo,
          alternatePhoneNumber,
          sendSMSToPhone,
          canReceiveSms,
        } = params.row;
        // Determine which phone number should have the SMS icon
        const shouldShowSmsIconPrimary =
          canReceiveSms && sendSMSToPhone === contactNo;
        const shouldShowSmsIconAlternate =
          canReceiveSms && sendSMSToPhone === alternatePhoneNumber;
        return (
          <div className="contact_container"
          >
            {contactNo && (
              <div className="icon_center_wrapper"

              >
                {contactNo}
                {shouldShowSmsIconPrimary && (
                  <Icon path={mdiMessageProcessing} size={0.8} />
                )}
              </div>
            )}
            {alternatePhoneNumber && (
              <div
                className="icon_center_wrapper"
              >
                {alternatePhoneNumber}
                {shouldShowSmsIconAlternate && (
                  <Icon path={mdiMessageProcessing} size={0.8} />
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "startDate",
      headerName: t("users.startDate"),
      width: 200,
      minWidth: 250,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: "endDate",
      headerName: t("users.endDate"),
      width: 200,
      minWidth: 250,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: "projectList",
      headerName: t("users.projectList"),
      width: 200,
      minWidth: 250,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className="cursor_pointer color_blue">
            {params.value.map((project: any) => (
              <h5 className="m_5" onClick={() => selectProject(project.projectId, params)} >{project.projectName}</h5>
            ))}
          </div>

        );
      }
    },
  ];
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const refreshUsers = () => {
    //setSnackbarMessage("User added successfully");
    fetchUsers();
  };

  const handleRowClick = (params: any) => {
    setSelectedUser(params.row);
    setAddUserDrawerOpen(true);
  };
  const fetchUsers = async () => {
    var param = {
      SearchText: searchKey
    }
    userService.getUserByCustomerId(customerId, param).then((response) => {
      setUserList(response.userList);
      setLoading(false);
    });

  };

  useEffect(() => {
    setTimeout(() => {
      fetchUsers();
    }, 300);

  }, [customerId, searchKey]);

  // handle for drawer
  const [addUserDrawerOpen, setAddUserDrawerOpen] = React.useState(false);
  // handle for opening drawer
  const handleDrawerOpen = () => {
    setAddUserDrawerOpen(true);
  };

  // handle for closing drawer
  const handleDrawerClose = () => {
    setSelectedUser(undefined);
    setAddUserDrawerOpen(false);
  };

  // Handle input changes
  const handleChange = (event: any) => {
    const value = event.target.value;
    setSearchKey(value);
  };

  const rows = userList.map((user: IUserList) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    userType: user.userType,
    userTypeId: user.userTypeId,
    contactNo: user.contactNo,
    alternatePhoneNumber: user.alternatePhoneNumber,
    sendSMSToPhone: user.sendSMSToPhone,
    canReceiveSms: user.canReceiveSms,
    startDate: dayjs(user.startDate).format(process.env.REACT_APP_DATE_FORMAT),
    endDate: dayjs(user.endDate).format(process.env.REACT_APP_DATE_FORMAT),
    projectList: user.projectList
  }));
  return (
    <div className={styles.Gridcontainer}>
      <Grid
        container
        className={styles.heading_container}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid
          className={styles.chip_container}
          container
          item
          xs={6}
          alignItems="center"
          justifyContent="flex-start"
        >
          <TextField
            id="search"
            name="search"
            placeholder={t("users.search")}
            variant="outlined"
            className="width30"
            value={searchKey} // Use searchKey directly here
            onChange={handleChange} // Updated handleChange function
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchKey ? (
                    <IconButton
                      aria-label="clear search"
                      onClick={() => setSearchKey("")} // Clear the search key
                    >
                      <Icon path={mdiClose} size={0.8} />
                    </IconButton>
                  ) : (
                    <Icon path={mdiMagnify} size={1} />
                  )}
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            onClick={handleDrawerOpen}
            className="primary-button rounded"
            id="add_user_btn"
          >
            {t("users.add_user_btn")}
          </Button>
        </Grid>
      </Grid>
      <Datatable
        rows={rows}
        columns={columns}
        page={page}
        rowsPerPage={rowsPerPage}
        pagination
        rowCount={count}
        paginationMode="server"
        // onPageChange={handlePageChange}
        pageSize={rowsPerPage}
        loading={loading}
        error={error}
        getRowHeight="auto"
        onRowClick={handleRowClick}
      />

      <AddUsers
        open={addUserDrawerOpen}
        onClose={handleDrawerClose}
        onUserAdded={refreshUsers}
        user={selectedUser}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Users;
