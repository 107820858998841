import * as React from "react";
import styles from "./datatable.module.scss";
import Box from "@mui/material/Box";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Button, Skeleton, Typography, styled, useTheme } from "@mui/material";
import CustomPagination from "./customPagination";
import { useEffect, useState } from "react";
import { useDrawer } from "../../utils/DrawerContext";

// custom overlay button
const DatatableOverlayButton: React.FC<any> = ({ overlayButtonLink }) => {
  return overlayButtonLink ? (
    <Button
      variant="contained"
      className="primary-button"
      href={overlayButtonLink}
      sx={{ padding: "8px 30px", color: "#FFF" }}
    >
      Create New
    </Button>
  ) : null;
};

// custom overlay button styles
const CustomNoRowsOverlayGrid = styled(GridOverlay)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "3rem 0rem",
});

const CustomNoRowsOverlay: React.FC<any> = ({
  overlayButtonLink,
  overlayButtonText,
}) => {
  return (
    <CustomNoRowsOverlayGrid className={styles.noRowsOverlay}>
      <img
        alt=""
        src={process.env.PUBLIC_URL + "/assets/no_records.svg"}
        className="empty_img"
      />
      <Typography className={styles.empty_msg}>
        {overlayButtonText
          ? overlayButtonText
          : "There are no records created yet"}
      </Typography>
      <DatatableOverlayButton overlayButtonLink={overlayButtonLink} />
    </CustomNoRowsOverlayGrid>
  );
};

const Datatable = (props: any) => {
  // Custom loading overlay
  const CustomLoadingOverlay: React.FC<any> = ({ pageSize, columns }) => {
    return (
      <GridOverlay className={styles.skeletonLoader}>
        {/* Skeleton for DataGrid Rows */}
        {[...Array(pageSize)].map((_, rowIndex) => (
          <div
            key={rowIndex}
            className={styles.skeleton}
          >
            {props.columns.map((column: any) => (
              <div
                key={column.field}              

              >
                <Skeleton
                  key={column.field}
                  variant="rectangular"
                  height={15}
                  className={styles.rectangular}
   
                />
              </div>
            ))}
          </div>
        ))}
      </GridOverlay>
    );
  };
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100); // 100 default page size
  useEffect(() => {
    if (props.rowCount != 0) { setPageSize(props.rowCount) }
  }, [props.rowCount])
  const totalPages = Math.ceil(props.rowCount / pageSize);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // handle the click for pagination
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  // Handle rows per page change
  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page
  };
  // Handler for previous page
  const handlePreviousPage = () => {
    if (props.page > 0) {
      props.onPageChange(props.page - 1);
    }
  };

  // Handler for next page
  const handleNextPage = () => {
    if (props.page < totalPages - 1) {
      props.onPageChange(props.page + 1);
    }
  };

  // Handler for last page
  const handleLastPage = () => {
    if (props.page < totalPages - 1) {
      props.onPageChange(totalPages - 1);
    }
  };

  // Handler for first page
  const handleFirstPage = () => {
    if (props.page > 0) {
      props.onPageChange(0);
    }
  };

  const { drawerOpen } = useDrawer();

  useEffect(() => {
    // Force DataGrid to resize when drawer state changes
    window.dispatchEvent(new Event("resize"));
  }, [drawerOpen]);

  return (
    <Box
      className={
        `${styles.datatableContainer}` +
        " ContainerwithTopBanner " +
        `${drawerOpen ? styles.drawerOpenClass : styles.drawerClosedClass}` +
        ` ${props.className}`
      }
    //className= // Toggle the key based on drawer state
    >
      {pageSize > 0 &&
        <DataGrid
          className={styles.datatableContainer_table}
          //rows={props.rows}
          rows={props.loading ? [] : props.rows} // Show empty rows when loading
          columns={props.columns}
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(paginationModel) => {
            const newPage = paginationModel.page ?? 0;
            props.onPageChange(newPage);
          }}
          onRowClick={props.onRowClick}
          slots={{
            // noRowsOverlay: () => (
            //   <CustomNoRowsOverlay overlayButtonLink={props.overlayButtonLink} />
            // ),
            noRowsOverlay: props.loading
              ? CustomLoadingOverlay
              : () => (
                <CustomNoRowsOverlay
                  overlayButtonText={props.overlayButtonText}
                  overlayButtonLink={props.overlayButtonLink}
                />
              ),
            pagination: () => (
              <CustomPagination
                page={props.page}
                pageCount={totalPages}
                onPageChange={handlePageChange}
                handleFirstPage={handleFirstPage}
                handleLastPage={handleLastPage}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            ),
          }}
          pageSizeOptions={[10]}
          sx={{
            borderRadius: "14px",
            '.MuiDataGrid-overlayWrapper': {
              height: 'auto', // Override the height of the overlay wrapper
            },
            '.MuiDataGrid-overlayWrapperInner': {
              height: 'auto !important' , // Override the height of the overlay wrapper inner
            }

          }}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnMenu
          checkboxSelection={props.checkboxSelection}
          onCellClick={props.onCellClick}
          autoHeight={props.autoHeight}
          getRowHeight={() => props.getRowHeight}
          hideFooterPagination={true}
        />
      }
    </Box>
  );
};

export default Datatable;
