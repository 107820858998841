import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import styles from "./BillingInfoDrawer.module.scss";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  styled,
} from "@mui/material";
import { IAddTaskDrawerProps, IContactDetails } from "../../../interfaces";
import { SMSSwitch, StyledDrawer } from "../../../components";
import { validateContact } from "../../../utils/ContactValidation";
import IAddContactDrawerProps from "../../../interfaces/IAddContactDrawerProps";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { useTranslation } from "react-i18next";
import BillingInformation from "../Billing-Information";

interface BillingDrawerProps {
    open?: any;
    onClose?: () => void;
  }

const BillingInfoDrawer: React.FC<BillingDrawerProps> = (props) => {
  const {
    open,
    onClose = () => { }
  } = props;
  const [fullHeight, setFullHeight] = useState(true);
  const handleCancel = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <StyledDrawer
      className={styles.rightDrawer}
      open={props.open}
      anchor="right"
      onClose={props.onClose}
    >
      <div className={styles.container}>
      <BillingInformation fullHeight={true} onCancel={handleCancel}></BillingInformation>
      </div>
    </StyledDrawer>
  );
};
export default BillingInfoDrawer;
