import React, { useEffect, useRef, useState } from "react";
import styles from "./attachedDocuments.module.scss";
import Datatable from "../../components/datatable/datatable";
import Icon from "@mdi/react";
import PrintIcon from "@mui/icons-material/Print";

import { mdiClose, mdiCloseCircleOutline, mdiMagnify } from "@mdi/js";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useLocation, useParams } from "react-router-dom";
import { IDisplayTask } from "../../interfaces/IDisplayTask";
import { AddDocumentNote } from "../../containers";
import DocViewer, {
  DocViewerRenderers,
  IHeaderOverride,
} from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import documentServices from "../../api/services/DocumentService";
import { IEntityTypeList } from "../../interfaces/IEntityTypeList";
import { decryptData } from "../../utils/CryptoJS";
import IDocumentParam from "../../interfaces/IDocumentParam";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { mdiDownload } from "@mdi/js";
import { UserTypeId } from "../../enums";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

type Document = {
  uri: string;
  fileType: string;
  fileName: string;
};

const AttachedDocuments: React.FC = () => {
  const location = useLocation();
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>("");
  const projectId = location.state?.projectId;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [showNote, setShowNote] = useState<boolean>(false);
  const [showDocument, setShowDocument] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [fileNameError, setFileNameError] = useState<string | null>(null);
  const [count, setCount] = React.useState(0);
  const [documentList, setDocumentList] = useState<any[]>([]);
  const [notes, setNotes] = useState<any[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<Document[] | any>();
  const [entityTypesList, setentityTypeList] = useState<IEntityTypeList[]>([]);
  const [entityTypeId, setEntityTypeId] = useState<number | string>(10);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const fileNameRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  var userDetails: any = localStorage.getItem("userDetails");
  var userDetailsObj = decryptData(userDetails);
  const pageCount = Math.ceil(count / rowsPerPage);
  const columns: GridColDef[] = [
    {
      field: "fileName",
      headerName: t("AttachedDocuments.fileName"),
      width: 120,
      minWidth: 150,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (doc: any) => {
        return (
          <div
            className="projectLink text_ellipsis"

            title={doc.value}
          >
            <span>{doc.value}</span>
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: t("AttachedDocuments.description"),
      width: 120,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: "entityTypeName",
      headerName: t("AttachedDocuments.entityTypeName"),
      width: 120,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: "LastModifiedDate",
      headerName: t("AttachedDocuments.LastModifiedDate"),
      width: 150,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  // To handle field error focus
  const handleFocus = (errors: any) => {
    if (errors) {
      fileNameRef.current?.focus();
    }
  };
  useEffect(() => {
    sessionStorage.setItem("ActiveTabIndex", JSON.stringify(6));
    const getEntityTypeData = async () => {
      try {
        const data = await documentServices.getEntityTypeList();
        if (data.isSuccess) {
          const excludedTypes = ["Budget", "Participate", "Payment"];
          const sortedEntityTypes = data.entityTypes
            .filter(
              (entity: any) => !excludedTypes.includes(entity.entityTypeName)
            )
            .sort((a: any, b: any) =>
              a.entityTypeName.localeCompare(b.entityTypeName)
            );
          setentityTypeList(sortedEntityTypes);
          fetchDocuments(projectId, entityTypeId);
        }
      } catch (error: any) {
        setError(error.response.data.errors.join(", "));
      }
    };
    getEntityTypeData();
  }, [projectId, page, rowsPerPage, openSnackbar, reloadFlag]);

  // Utility function to format date
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const rows = documentList?.map((document) => ({
    id: document.attachmentId,
    fileName: document.fileName,
    description: document.description,
    entityTypeName: document.entityTypeName,
    LastModifiedDate: document.modifiedDate
      ? dayjs(document.modifiedDate).format(process.env.REACT_APP_DATE_FORMAT)
      : "",
    attachmentURL: document.attachmentURL,
  }));
  // To fetch document list
  const fetchDocuments = async (projectId: any, entityTypeId: any) => {
    try {
      // const userId = userDetailsObj.userId;
      const paginationParams: IDocumentParam = {
        pageNumber: page + 1,
        pageSize: rowsPerPage,
        sortBy: "0",
        sortDescending: false,
      };
      var userTypeId = localStorage.getItem("userRoleId");
      var userId: any = localStorage.getItem("userId");
      const data = await documentServices.getFileListByFilter(
        projectId,
        entityTypeId,
        userId,
        userTypeId,
        paginationParams
      );
      setDocumentList(data.attachments.attachments);
      setCount(data.totalCount);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch documents");
      setLoading(false);
    }
  };

  //To fetch notes list
  const fetchNotes = async (entityTypeId: any, entityId: any) => {
    try {
      const data = await documentServices.getNoteByDocumentId(
        entityTypeId,
        entityId
      );
      setNotes(data.noteDocuments);
    } catch (error) {
      setError("Failed to fetch notes");
    }
  };

  //To handle document preview
  const handleCellClick = (params: any) => {
    const selectedDoc = documentList.find(
      (doc) => doc.attachmentId === params.row.id
    );
    if (selectedDoc) {
      setSelectedFileName(selectedDoc.fileName);
      setSelectedDocumentId(selectedDoc.attachmentId);
    }
    fetchNotes(entityTypeId, selectedDoc.attachmentId);
    setShowNote(true);

    if (params.row.attachmentURL) {
      const fileType = params.row.attachmentURL
        .split(".")
        .pop()
        .split("?")[0]
        .toLowerCase();
      const document = {
        uri: params.row.attachmentURL,
        fileType,
        fileName: params.row.fileName,
      };
      setSelectedDocument([document]);
      setShowDocument(false);
      setTimeout(() => {
        setShowDocument(true);
      }, 0);
    }
  };

  const closePreview = (params: any) => {
    setShowDocument(false);
  };

  // To handle the change dropdown
  const handleEntitiesChange = (
    event: SelectChangeEvent<typeof entityTypeId>
  ) => {
    const value = event.target.value;
    setEntityTypeId(value);
    fetchDocuments(projectId, value);
    fetchNotes(value, projectId);
    setPage(0);
    setShowDocument(false);
    setShowNote(false);
  };

  // To handle save notes
  const handleSaveNote = (newNote: any) => {
    setNotes((prevNotes) => [...prevNotes, newNote]);
    setEnableSaveButton(true);
  };

  // handle for drawer
  const [addNoteDrawerOpen, setAddNoteDrawerOpen] = React.useState(false);
  // handle for opening drawer
  const handleAddNoteDrawerOpen = () => {
    setAddNoteDrawerOpen(true);
  };

  // handle for closing drawer
  const handleAddNoteDrawerClose = () => {
    setAddNoteDrawerOpen(false);
  };

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < pageCount) {
      setPage(newPage);
    }
  };

  //To handle Save Attachment
  const handleSaveAttachment = async () => {
    if (!selectedFileName.trim()) {
      setFileNameError("FileName is required.");
      handleFocus("FileName is required");
    } else {
      const payload = {
        AttachmentId: selectedDocumentId,
        UserId: userDetailsObj.userId,
        FileName: selectedFileName,
        Notes: notes
          .filter((note) => note.noteId === 0)
          .map((note) => note.notes),
      };

      try {
        const response = await documentServices.saveDocumentNotes(payload);
        if (response.isSuccess) {
          setSnackbarMessage(response.message);
          setOpenSnackbar(true);
          setShowNote(false);
          setReloadFlag((prev) => !prev);
          setEnableSaveButton(false);
        } else {
          setError("Failed to fetch documents");
        }
      } catch (error) {
        setError("Error saving document and notes:");
      }
    }
  };

  // to print the document
  const handlePrint = async (event: any) => {
    event.preventDefault();
    if (selectedDocument[0].fileType === "jpg") {
      printImage(selectedDocument[0].uri);
    } else {
      window.open(selectedDocument[0].uri);
    }
  };

  // To print the img file
  const printImage = (imageUrl: string) => {
    const printFrame = document.createElement("iframe");
    document.body.appendChild(printFrame);
    printFrame.style.position = "absolute";
    printFrame.style.top = "-10000px";
    printFrame.style.left = "-10000px";

    const img = printFrame.contentDocument?.createElement("img");
    if (img) {
      img.src = imageUrl;
      img.style.width = "100%";
      img.style.height = "100%";
      const body = printFrame.contentDocument?.body;
      if (body) {
        body.appendChild(img);
      }
    }
    printFrame.contentWindow?.focus();
    printFrame.contentWindow?.print();
  };

  //To download files
  const handleDownload = async () => {
    const uri = selectedDocument[0].uri;
    const fileName = selectedDocument[0].fileName;

    try {
      const response = await fetch(uri, { mode: "cors" });
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Failed to download the file:", error);
    }
  };

  return (
    <div className={styles.Gridcontainer}>
      <Grid
        container
        className={styles.search_container}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          className={styles.chip_container}
          container
          item
          sm={6}
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            "@media screen and (max-width:768px)": {
              width: "30% !important",
            },
          }}
        >

        </Grid>
        <Grid
          className={styles.chip_container}
          container
          item
          sm={2}
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            "@media screen and (max-width:768px)": {
              width: "70% !important",
            },
          }}
        >
          <Grid item container>
            <FormControl className="fieldWrapper">
              <InputLabel id="type_placeholder">Select Entity</InputLabel>
              <Select
                notched
                labelId="status_placeholder"
                id="status"
                placeholder="Select Entity"
                displayEmpty
                value={entityTypeId}
                className="inlineSelect selectBox"
                input={<OutlinedInput notched label="Select Entity" />}
                onChange={handleEntitiesChange}
              >
                {entityTypesList?.filter((lookup) => lookup.entityTypeId <= 10 || lookup.entityTypeId == 18).map((lookup) => (
                  <MenuItem
                    key={lookup.entityTypeId}
                    value={lookup.entityTypeId}
                  >
                    {lookup.entityTypeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid sm={6} item container className={styles.table_wrapper_container}>
          <Grid item container>
            <Datatable
              rows={rows}
              columns={columns}
              page={page}
              rowsPerPage={rowsPerPage}
              pagination
              onPageChange={handlePageChange}
              rowCount={count}
              paginationMode="server"
              loading={loading}
              error={error}
              pageSize={rowsPerPage}
              onCellClick={handleCellClick}
              className={styles.table_wrapper}
              autoHeight={true}
            />
          </Grid>
          {showNote && (
            <>
              <Grid
                item
                container
                className="fieldWrapper"
                sx={{ marginTop: "8px" }}
              >
                <TextField
                  placeholder="Edit File Name"
                  label="Edit File Name"
                  variant="outlined"
                  value={selectedFileName}
                  error={!!fileNameError}
                  helperText={fileNameError}
                  inputRef={fileNameRef}
                  onInput={() => setEnableSaveButton(true)}
                  onChange={(e) => setSelectedFileName(e.target.value)}
                />
              </Grid>
              <input
                type="hidden"
                name="hiddenField"
                value={selectedDocumentId}
              />
              <Grid item container xs={12}>
                <Grid
                  container
                  className={styles.heading_container}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Grid
                    className={styles.chip_container}
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <h3 className="mr_16"> Notes: </h3>
                    <Button
                      variant="contained"
                      onClick={handleAddNoteDrawerOpen}
                    >
                      Add Note
                    </Button>
                  </Grid>
                </Grid>
                <TableContainer component={Paper}>
                  <Table size="small">
                    {notes.length > 0 ? (
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Notes
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Created Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    ) : null}
                    <TableBody>
                      {notes?.map((note, index) => (
                        <TableRow key={index} className="cursor_pointer">
                          <TableCell>{note.notes}</TableCell>
                          <TableCell>
                            {note.createdDate
                              ? dayjs(note.createdDate).format(process.env.REACT_APP_DATE_FORMAT)
                              : ""}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <AddDocumentNote
                open={addNoteDrawerOpen}
                onClose={handleAddNoteDrawerClose}
                onSaveNote={handleSaveNote}
              />
              <Grid item container className="submitButtons">
                <Button
                  variant="contained"
                  className="primary-button"
                  onClick={handleSaveAttachment}
                  disabled={!enableSaveButton}
                >
                  Save
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item container sm={6} className={styles.document_container}>
          {showDocument ? (
            <>
              <Grid item sm={12} container className={styles.document_viewer}>
                <Grid
                  item
                  container
                  sm={12}
                  className={styles.previewCloseButton}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  {selectedDocument[0].fileType !== "pdf" && (
                    <IconButton
                      onClick={handleDownload}
                      sx={{ justifySelf: "flex-end" }}
                    >
                      <Icon path={mdiDownload} size={1} color="#000" />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={handlePrint}
                    sx={{ justifySelf: "flex-end" }}
                  >
                    <PrintIcon sx={{ color: "#000" }} />
                  </IconButton>
                  <IconButton
                    onClick={closePreview}
                    sx={{ justifySelf: "flex-end" }}
                  >
                    <Icon path={mdiCloseCircleOutline} size={1} color="#000" />
                  </IconButton>
                </Grid>
                <Grid item sm={12} container>
                  {selectedDocument[0].fileType === "png" ||
                    selectedDocument[0].fileType === "jpg" ||
                    selectedDocument[0].fileType === "jpeg" ? (
                    <Zoom>
                      <img
                        src={selectedDocument[0].uri}
                        alt={selectedDocument[0].fileName}
                        className="doc_img"
                      />
                    </Zoom>
                  ) : (
                    <DocViewer
                      documents={selectedDocument}
                      pluginRenderers={DocViewerRenderers}
                      className="doc_viewer"
                    />
                  )}
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </Grid>
    </div>
  );
};

export default AttachedDocuments;
