import React, { useCallback, useEffect, useState, useTransition } from "react";
import styles from "./participants.module.scss";
import Datatable from "../../components/datatable/datatable";
import { Button, Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useLocation, useParams } from "react-router-dom";
import { projectService } from "../..//api";
import { IParticipantData, IProjectParam } from "../../interfaces";
import { decryptData } from "../../utils/CryptoJS";
import AddParticipants from "./AddParticipants/AddParticipants";
import { mdiMessageProcessing } from "@mdi/js";
import Icon from "@mdi/react";
import IGetParticipant, {
  ParticipantDetailsViewModel,
} from "../../interfaces/IGetParticipant";
import { useTranslation } from "react-i18next";

const Participants: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const projectId = location.state?.projectId;
  var userDetails: any = localStorage.getItem("userDetails");
  var userDetailsObj = decryptData(userDetails);
  var customerId = parseInt(sessionStorage.getItem("customerId") as string, 10);
  var userId = localStorage.getItem("userId") as string;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [count, setCount] = React.useState(0);
  const pageCount = Math.ceil(count / rowsPerPage);
  const [selectedParticipant, setSelectedParticipant] =
    useState<ParticipantDetailsViewModel>();
  const [Participantlist, setParticipantlist] = useState<IParticipantData[]>(
    []
  );
  const [totalParticipants, setTotalParticipants] = useState<number>(0);
  const Participantlistobj: ParticipantDetailsViewModel = {
    userAccessId: 0,
    userTypeId: 0,
    userId: "",
    isLicencedUser: false,
    addressId: 0,
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    contactNo: "",
    alternateContactNumber: "",
    email: "",
    companyTypeId: 0,
    companyTypeName: "",
    companyId: 0,
    companyName: "",
    contactPerson: "",
    projectRole: "",
    canRecieveSms: false,
    sendSmsToPhone: "",
  };
  const columns: GridColDef[] = [
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 120,
      flex: 1,
      minWidth: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className="projectLink cursor_pointer">
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "CompanyType",
      headerName: t("Participants.CompanyType"),
      width: 120,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "address",
      headerName: t("Participants.address"),
      width: 150,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "ContactPerson",
      headerName: t("Participants.ContactPerson"),
      width: 150,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "phones",
      headerName: t("Participants.phones"),
      width: 200,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params) => {
        const {
          contactNo,
          alternatePhoneNumber,
          sendSmsToPhone,
          canReceiveSms,
        } = params.row;
        // Determine which phone number should have the SMS icon
        const shouldShowSmsIconPrimary =
          canReceiveSms && sendSmsToPhone === contactNo;
        const shouldShowSmsIconAlternate =
          canReceiveSms && sendSmsToPhone === alternatePhoneNumber;
        return (
          <div

            className={styles.participant_container}
          >
            {contactNo && (
              <div className={styles.contact_div}
              >
                {contactNo}
                {shouldShowSmsIconPrimary && (
                  <Icon path={mdiMessageProcessing} size={0.8} />
                )}
              </div>
            )}
            {alternatePhoneNumber && (
              <div className={styles.alternate_div}
              >
                {alternatePhoneNumber}
                {shouldShowSmsIconAlternate && (
                  <Icon path={mdiMessageProcessing} size={0.8} />
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: t("Participants.email"),
      width: 200,
      minWidth: 250,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
  ];
  const refreshParticipants = () => {
    fetchParticipants();
  };
  const fetchParticipantbyId = async (userAccessId: number) => {
    try {
      const response = await projectService.GetParticipantAsync(userAccessId);
      const participantData = response.participantDetailsViewModel;
      // Set the selected participant data to state (or handle it as needed)
      setSelectedParticipant(participantData);
    } catch (error) {
      console.error("Error fetching participant:", error);
      setError("Failed to fetch participant details");
    }
  };
  const handleRowClick = (params: any) => {
    const userAccessId = params.row.projectAccessId;
    fetchParticipantbyId(userAccessId);
    setAddParticipantDrawerOpen(true);
  };
  const fetchParticipants = useCallback(async () => {
    if (projectId && customerId) {
      setLoading(true);
      try {
        const params: IProjectParam = {
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortBy: undefined,
          projectTypeId: undefined,
          sortDescending: false,
          searchKey: "",
          isGantt: false,
        };
        const response = await projectService.GetProjectParticipantList(
          projectId,
          customerId,
          params
        );
        if (response.isSuccess) {
          setParticipantlist(response.getProjeParticipantResulViewModel);
          setCount(response.totalCount); // Adjust if the count is provided separately
          setLoading(false);
        } else {
          setError("Failed to fetch participants");
          setLoading(false);
        }
      } catch (error) {
        setError("An error occurred while fetching participants");
        setLoading(false);
      }
    }
  }, [projectId, customerId, page, rowsPerPage]);

  useEffect(() => {
    fetchParticipants();
  }, [fetchParticipants]);
  useEffect(() => {
    setTotalParticipants(Participantlist.length);
  }, [Participantlist]);

  // handle for drawer
  const [addParticipantDrawerOpen, setAddParticipantDrawerOpen] =
    React.useState(false);
  // handle for opening drawer
  const handleDrawerOpen = () => {
    setAddParticipantDrawerOpen(true);
  };
  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < pageCount) {
      setPage(newPage);
    }
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page
  };
  // handle for closing drawer
  const handleDrawerClose = () => {
    setSelectedParticipant(Participantlistobj);
    setAddParticipantDrawerOpen(false);
  };

  const rows = Participantlist.map((participant: IParticipantData) => ({
    id: participant.contactId == '' ? participant.userAccessId : participant.contactId,
    projectAccessId: participant.userAccessId,
    CompanyName: participant.companyName,
    CompanyType: participant.companyType,
    address: participant.address,
    ContactPerson: participant.contactPerson,
    contactNo: participant.contactNo,
    sendSmsToPhone: participant.sendSMSToPhone,
    canReceiveSms: participant.canReceiveSms,
    alternatePhoneNumber: participant.alternatePhoneNumber,
    email: participant.email,
  }));
  return (
    <div className={styles.Gridcontainer}>
      <Grid
        container
        className={styles.heading_container}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid
          className={styles.chip_container}
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="flex-start"
        >
          {/* <h3> Participants </h3> */}
          <Button
            variant="contained"
            onClick={handleDrawerOpen}
            className="primary-button rounded"
            id="add_participants_btn"
          >
            {t("Participants.add_participants_btn")}
          </Button>
        </Grid>
      </Grid>
      <Datatable
        rows={rows}
        columns={columns}
        page={page}
        rowsPerPage={rowsPerPage}
        pagination
        rowCount={count}
        paginationMode="server"
        onPageChange={handlePageChange}
        pageSize={rowsPerPage}
        loading={loading}
        error={error}
        getRowHeight="auto"
        onRowClick={handleRowClick}
      />

      <AddParticipants
        open={addParticipantDrawerOpen}
        onClose={handleDrawerClose}
        onParticipantAdded={refreshParticipants}
        participant={selectedParticipant}
      />
    </div>
  );
};

export default Participants;
