import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./addParticipants.module.scss";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiClose, mdiMagnify, mdiMessageProcessing } from "@mdi/js";
import { SMSSwitch, StyledDrawer } from "../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { projectService } from "../../../api";
import {
  IVendorandVendorTypes,
  IVendorWithTypeIds,
  IContactResponse,
  IAddParticipant,
  IAddTaskDrawerProps,
  IContactData,
  IAddParticipantInputFields,
} from "../../../interfaces";
import ParticipantSearchList from "../ParticipantSearchList/participant-search-list";
import { UserTypeId } from "../../../enums";
import { validateParticipant } from "../../../utils/ParticipantValidation";
import IAddParticipantsDrawerProps from "../../../interfaces/IAddParticipantsDrawerProps";
import { decryptData } from "../../../utils/CryptoJS";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { ParticipantDetailsViewModel } from "../../../interfaces/IGetParticipant";
import { useTranslation } from "react-i18next";

const AddParticipants: React.FC<IAddParticipantsDrawerProps> = ({
  open,
  onClose,
  onParticipantAdded,
  participant,
}) => {
  const ParticipantObj = {
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    contactNo: "",
    alternatePhoneNumber: "",
    sendSMSToPhone: "",
    email: "",
    canRecieveSms: false,
    contactPerson: "",
  };
  const { t } = useTranslation();
  const [addParticipantDrawerOpen, setAddParticipantDrawerOpen] =
    React.useState(false);
  const [searchParam, setSearchParam] = useState<string>("");
  const [vendorTypes, setVendorTypes] = useState<IVendorandVendorTypes[]>([]);
  const [selectedVendorTypeId, setSelectedVendorTypeId] = useState<number | "">(
    ""
  );
  const [isSearching, setIsSearching] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | "">("");
  const [selectedContactId, setSelectedContactId] = useState<string | "">("");
  const [filteredVendors, setFilteredVendors] = useState<IVendorWithTypeIds[]>(
    []
  );
  const [showLicensedUserToggle, setShowLicensedUserToggle] = useState(false);
  const Participantlistobj: ParticipantDetailsViewModel = {
    userAccessId: 0,
    userTypeId: 0,
    userId: "",
    isLicencedUser: false,
    addressId: 0,
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    contactNo: "",
    alternateContactNumber: "",
    email: "",
    companyTypeId: 0,
    companyTypeName: "",
    companyId: 0,
    companyName: "",
    contactPerson: "",
    projectRole: "",
    canRecieveSms: false,
    sendSmsToPhone: "",
  };
  const [vendors, setVendors] = useState<IVendorWithTypeIds[]>([]);
  const [userId, setUserId] = useState<string>("");
  const [contactPersons, setContactPersons] = useState<IContactData[]>([]);
  const [contact, setContact] = useState<IContactData>();
  const [contactDetails, setContactDetails] = useState(ParticipantObj);
  const location = useLocation();
  const projectId = location.state?.projectId;
  var customerId = parseInt(sessionStorage.getItem("customerId") as string, 10);

  const [selectedUserRole, setSelectedUserRole] = useState<UserTypeId | "">("");
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const [savingData, setSavingData] = useState(false);

  const addParticipantObj = {
    userAccessId: 0,
    userId: "",
    projectId: 0,
    isActive: true,
    projectRole: "", //Hard coded for now.
    userTypeId: 0,
    IsLicensed: false,
  };
  const LICENSED_USER_ROLES = [3, 4];
  const [addParticipantDetails, setAddParticipantDetails] =
    useState<IAddParticipant>(addParticipantObj);
  const formData = {
    companyTypeId: 0,
    companyName: 0,
    contactId: "",
    userRoleType: 0,
    isLicensed: false,
  };
  const [formDetails, setFormDetails] =
    useState<IAddParticipantInputFields>(formData);
  const navigate = useNavigate();
  // Convert enum to array for dropdown
  const userTypes = Object.keys(UserTypeId)
    .filter((key) => !isNaN(Number(UserTypeId[key as any])))
    .map((key) => ({
      key: UserTypeId[key as keyof typeof UserTypeId],
      value: key,
    })).filter((userType) => [3, 4].includes(userType.key)); //Show Only "Standard" and "Read-Only" as Usertype 
  const [errors, setErrors] = useState({
    companyTypeId: false,
    companyName: false,
   // contactId: false,
  });
  const [touchedFields, setTouchedFields] = useState<Record<any, any>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);

  // toggle event for drawer
  const handleDrawerToggle = () => {
    if (isSearching) {
      setIsSearching(false);
      return;
    }
    setSearchParam("");
    setSelectedVendorTypeId("");
    setSelectedCompanyId("");
    setContactDetails(ParticipantObj);
    setSelectedContactId("");
    setAddParticipantDrawerOpen(false);
    setValidationErrors({});
    setAddParticipantDetails(addParticipantObj);
    setContactDetails(ParticipantObj);
    setFilteredVendors([]);
    setSelectedUserRole("");
    setFormDetails(formData);
    setContactPersons([]);
    setErrors({
      companyTypeId: false,
      companyName: false,
      //contactId: false,
    });
    setTouchedFields({});
    setValidationErrors({});
    setFormTouched(false);
    if (onClose) {
      onClose();
    }
    setSaveEnabled(false);
    setIsFormValid(false);
  };
  useEffect(() => {
    const valid = validateForm();
    setIsFormValid(valid); // Update isFormValid based on validation result
  }, [formDetails]);

  const handleBlur = (field: any) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
    if (!formTouched) {
      setFormTouched(true); // Set formTouched to true on first blur
    }
  };
  const validateForm = () => {

    const newErrors = {
      companyTypeId: !formDetails.companyTypeId,
      //contactId: !formDetails.contactId,
      companyName: !formDetails.companyName,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(Boolean);
  };
  //fetching the contacts by using a search param
  const fetchContacts = async (
    searchParam: string
  ): Promise<IContactResponse | null> => {
    if (searchParam.length >= 3) {
      try {
        const contacts = await projectService.getContactsList(
          customerId,
          searchParam
        );
        setContactPersons(contacts.contactsViewModel);
        setIsSearching(true);
        return contacts;
        // Handle the fetched contacts data as needed
      } catch (error) {
        console.error("Error fetching contacts:", error);
        return null;
      }
    } else {
      setIsSearching(false);
    }
    return null;
  };

  //fetching the vendor data with vendor type ids by using the customer id
  const fetchVendorData = async () => {
    try {
      const data = await projectService.vendorsWithVendorTypeIds(customerId);
      if (data) {
        setVendorTypes(data.vendorTypes);
        setVendors(data.vendorWithTypeIds);
      } else {
        console.error("Failed to fetch vendor type data");
      }
    } catch (error) {
      console.error("Failed to fetch the vendor type data");
    }
  };

  const updateContactDetails = (contact: IContactData) => {
    const addressDetails = contact.address ? contact.address.split(",") : [];
    const phoneNumbers = contact.contactNo ? contact.contactNo.split(" (") : [];
    setSelectedVendorTypeId(contact.vendorTypeId);
    filterVendorsByType(contact.vendorTypeId);

    setSelectedContactId(contact.contactId);
    setSearchParam("");
    setContactDetails({
      address1: addressDetails[0] || "",
      address2: addressDetails[1] || "",
      city: addressDetails[2] || "",
      state: addressDetails[3] || "",
      zipCode: addressDetails[4] || "",
      alternatePhoneNumber: contact.alternatePhoneNumber || "",
      contactNo: phoneNumbers[0] || phoneNumbers[1],
      email: contact.email || "",
      contactPerson: contact.contactPerson || "",
      canRecieveSms: contact.canReceiveSms || false,
      sendSMSToPhone: contact.sendSMSToPhone || "",
      companyName: contact.companyName,
    });
    setSelectedCompanyId(contact.companyId);
    // To ensure 'sendSMSToPhone' is printed correctly from state, use a useEffect if needed
    setUserId(contact.contactId);
  };
  useEffect(() => {
    if (participant?.userAccessId) {
      setSelectedCompanyId(participant.companyId);
      setSelectedVendorTypeId(participant.companyTypeId);
      setSelectedUserRole(participant.userTypeId);
      setSelectedContactId(participant.userId);
      const contactData: IContactData = {
        companyId: participant.companyId,
        contactId: participant.userId,
        companyType: participant.companyTypeName, // Assuming this is a string representation of the type
        companyName: participant.companyName,
        address: `${participant.address1}, ${participant.address2}, ${participant.city}, ${participant.state}, ${participant.zipCode}`,
        contactPerson: participant.contactPerson,
        contactNo: participant.contactNo,
        email: participant.email,
        firstName: "", // If firstName is not available in participant, set accordingly
        lastName: "", // If lastName is not available in participant, set accordingly
        canReceiveSms: participant.canRecieveSms,
        alternatePhoneNumber: participant.alternateContactNumber,
        vendorTypeId: participant.companyTypeId,
        sendSMSToPhone: participant.sendSmsToPhone, // Assuming this is correct based on your data model
      };
      setContact(contactData);
      updateContactDetails(contactData); // Use existing function to update the contact details
      setFormDetails({
        companyTypeId: participant.companyTypeId,
        companyName: participant.companyId,
        contactId: participant.userId,
        userRoleType: participant.userTypeId,
        isLicensed: participant.isLicencedUser,
      });

      setAddParticipantDetails({
        ...addParticipantDetails,
        userId: participant.userId,
        projectId: projectId, // Assuming projectId is already being fetched
        userTypeId: participant.userTypeId,
        IsLicensed: participant.isLicencedUser,
      });
    }
    fetchVendorData();

    fetchContacts(searchParam);
  }, [searchParam, participant]);
  //handling the input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParam(event.target.value);
  };

  const filterVendorsByType = (selectedTypeId: number) => {
    const filtered = vendors.filter((vendor) =>
      vendor.companyTypeIds.includes(selectedTypeId)
    );
    setFilteredVendors(filtered);
  };

  //handling the vendor type change
  const handleVendorTypeChange = (event: SelectChangeEvent<number>) => {
    const selectedTypeId = event.target.value as number;
    setSearchParam("");
    if (typeof selectedTypeId === "number") {
      setSelectedVendorTypeId(selectedTypeId);
      setContactDetails(ParticipantObj);
      filterVendorsByType(selectedTypeId);
      setFormDetails((prevDetails) => ({
        ...prevDetails,
        companyTypeId: selectedTypeId,
      }));
    }
  };
  const handleToggleChange = (checked: boolean) => {
    setAddParticipantDetails((prevDetails) => ({
      ...prevDetails,
      IsLicensed: checked,
    }));
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      isLicensed: checked,
    }));
  };

  const ContactRedirect = () => {
    navigate("/add-vendor", { replace: true });
  };
  const handleUserRoleChange = (event: SelectChangeEvent<UserTypeId>) => {
    const userRole = event.target.value as UserTypeId;
    setSelectedUserRole(userRole);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      userRoleType: "",
    }));
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      userRoleType: userRole,
    }));
  };
  //handling the company id change
  const handleCompanyIdChange = async (event: SelectChangeEvent<number>) => {
    const selectedCompanyId = Number(event.target.value);
    setSearchParam("");
    setContactDetails(ParticipantObj);
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      companyName: selectedCompanyId,
    }));
    if (typeof selectedCompanyId === "number") {
      setSelectedCompanyId(selectedCompanyId);
      await fetchContactsForCompany(selectedCompanyId);
    }
  };
  const fetchContactsForCompany = async (selectedCompanyId: number) => {
    const selectedCompany = filteredVendors.find(
      (vendor) => vendor.companyId === selectedCompanyId
    );
    if (selectedCompany) {
      try {
        const response = await projectService.getContactsList(
          customerId,
          selectedCompany.companyName
        );
        if (response && response.contactsViewModel) {
          const contacts = response.contactsViewModel.filter(
            (c: IContactData) =>
              c.companyType ===
              vendorTypes.find(
                (v) =>
                  v.companyTypeId === selectedVendorTypeId &&
                  c.companyId === selectedCompanyId
              )?.companyTypeName
          );
          setContactPersons(contacts);
        }
        // if (response.contactsViewModel[0].contactId === "") {
        //   await handleRowClick(response.contactsViewModel[0]);
        // }
        setSaveEnabled(true);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    }
  };
  const handleRowClick = async (contact: IContactData) => {
    const addressDetails = contact.address.split(",");
    const phoneNumbers = contact.contactNo.split(" (");
    updateContactDetails(contact);
    setIsSearching(false);
    setSaveEnabled(true);
  };
  const handleChange = (value: boolean) => {
    setContactDetails((prevContact) => ({
      ...prevContact,
      canrecievesms: value,
    }));
  };

  //handling the save for participant data
  const handleSaveAddParticipantData = async () => {
    if (validateForm() || saveEnabled) {
      setSavingData(true);

      var userDetailsObj: any = localStorage.getItem("userDetails");
      const userDetails = decryptData(userDetailsObj);
      const retrievedUserId = userDetails.userId;
      const formDetailsWithUserId = {
        ...formDetails,
        userId: retrievedUserId,
      };

      const errors = validateParticipant(formDetailsWithUserId);
      setValidationErrors(errors);

      if (Object.keys(errors).length === 0) {
        try {
          if (participant?.userAccessId) {
            const editParticipantData = JSON.stringify({
              userAccessId: participant.userAccessId,
              userId: userId,
              projectId: projectId,
              isLicensedUser: addParticipantDetails.IsLicensed,
              UserTypeId: selectedUserRole,
            });
            const response = await projectService.EditParticipantAsync(
              editParticipantData
            );
            if (response.isSuccess) {
              onParticipantAdded();
              handleDrawerToggle();
              setSavingData(false);
            } else if (!response.isSuccess) {
              console.error("error");
              setSavingData(false);
            }
          } else {
            const addParticipantData = JSON.stringify({
              userId: userId,
              projectId: projectId,
              isActive: true,
              isLicensedUser: addParticipantDetails.IsLicensed,
              UserTypeId: selectedUserRole !== "" ? selectedUserRole : null,
              vendorId: selectedCompanyId,
            });
            const response = await projectService.addParticipantAsync(
              addParticipantData
            );
            if (response.isSuccess) {
              onParticipantAdded();
              handleDrawerToggle();
              setSavingData(false);
            } else if (!response.isSuccess) {
              console.error("error");
              setSavingData(false);
            }
          }
        } catch (error: any) {
          console.error("Error:", error);
          setSavingData(false);
        }
        setSavingData(false);
      }
      setSavingData(false);
    }
  };

  const handlePhoneNumberChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
  };
  // Modify the useEffect to set the visibility of the Licensed User toggle
  useEffect(() => {
    if (participant?.userAccessId) {
      setSelectedUserRole(participant.userTypeId);
      setShowLicensedUserToggle(LICENSED_USER_ROLES.includes(participant.userTypeId));
    }
  }, [participant]);
  // Prevent default behavior for Enter key
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  return (
    <StyledDrawer className={styles.rightDrawer} open={open} anchor="right">
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        className={styles.addProjectcontainer}
        alignContent="space-between"
      >
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          className={styles.formContainer}
        >
          <Grid
            container
            item
            xs={12}
            className={styles.drawer_search_container}
            alignItems="center"
            justifyContent="flex-start"
          >
            <TextField
              placeholder={t("AddParticipants.search")}
              variant="outlined"
              sx={{ width: "50% !important" }}
              value={searchParam}
              disabled={!!participant?.userAccessId}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown} 
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchParam ? (
                      <IconButton
                        aria-label="clear search"
                        onClick={() => setSearchParam("")} // Clear the search key
                      >
                        <Icon path={mdiClose} size={0.8} />
                      </IconButton>
                    ) : (
                      <Icon path={mdiMagnify} size={1} />
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              onClick={ContactRedirect}
              className="primary-button rounded"
              disabled={isSearching}
            >
              {t("AddParticipants.create_new_btn")}
            </Button>
          </Grid>
          {isSearching ? (
            <ParticipantSearchList
              contactData={contactPersons}
              onRowClick={handleRowClick}
            />
          ) : (
            <Grid container item xs={12} spacing={2}>
              <Grid item container className="fieldWrapper">
                <FormControl className="inlineSelect" error={touchedFields.companyTypeId && errors.companyTypeId}>
                  <InputLabel id="type_placeholder">{t("AddParticipants.type_placeholder")}</InputLabel>
                  <Select
                    labelId="type_placeholder"
                    id="type"
                    placeholder="Company Type"
                    label="Company Type"
                    className="selectBox"
                    disabled={!!participant?.userAccessId}
                    onBlur={() => handleBlur("companyTypeId")}
                    onChange={handleVendorTypeChange}
                    value={selectedVendorTypeId}
                  >
                    {vendorTypes.map((vendorType) => (
                      <MenuItem
                        key={vendorType.companyTypeId}
                        value={vendorType.companyTypeId}
                      >
                        {vendorType.companyTypeName}
                      </MenuItem>
                    ))}
                  </Select>
                  {touchedFields.companyTypeId && errors.companyTypeId && (
                    <FormHelperText>Company Type is required</FormHelperText> // Display helper text
                  )}
                </FormControl>
              </Grid>
              <Grid item container className="fieldWrapper">
                <FormControl className="inlineSelect" error={touchedFields.companyName && errors.companyName}>
                  <InputLabel id="cn_placeholder">{t("AddParticipants.cn_placeholder")}</InputLabel>
                  <Select
                    labelId="cn_placeholder"
                    id="cn_placeholder"
                    placeholder="Company Name"
                    label="Company Name"
                    className="selectBox"
                    value={selectedCompanyId}
                    onChange={handleCompanyIdChange}
                    onBlur={() => handleBlur("companyName")}
                    disabled={!!participant?.userAccessId}
                  >
                    {filteredVendors.map((vendor) => (
                      <MenuItem key={vendor.companyId} value={vendor.companyId}>
                        {vendor.companyName}
                      </MenuItem>
                    ))}
                  </Select>
                  {touchedFields.companyName && errors.companyName && (
                    <FormHelperText>Company Name is required</FormHelperText> // Display helper text
                  )}
                </FormControl>
              </Grid>
              {participant?.userAccessId ? (
                // Render when participant exists
                <Grid item xs={12} className="fieldWrapper">
                  <FormControl className="inlineSelect">
                    <InputLabel id="cp_placeholder">{t("AddParticipants.cp_placeholder")}</InputLabel>
                    <Select
                      labelId="cp_placeholder"
                      label="Contact Person"
                      placeholder="Contact Person"
                      className="selectBox"
                      value={participant.contactPerson || ""} // Using participant.address1 when participant exists
                      disabled={true} // Disable the field when participant exists
                      error={!!validationErrors.contactId}
                    >
                      <MenuItem value={participant.contactPerson}>
                        {participant.contactPerson}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                // Render when participant does not exist
                <Grid item xs={12} className="fieldWrapper">
                  <FormControl className="inlineSelect" >
                    <InputLabel id="cp_placeholder">{t("AddParticipants.cp_placeholder")}</InputLabel>
                    <Select
                      labelId="cp_placeholder"
                      label="Contact Person"
                      placeholder="Contact Person"
                      className="selectBox"
                      value={selectedContactId || ""} // Use the selected contact ID
                      onChange={(e) => {
                        const selectedId = e.target.value;
                        setSelectedContactId(selectedId);
                        setFormDetails((prevDetails) => ({
                          ...prevDetails,
                          contactId: selectedId,
                        }));

                        // Find the selected contact based on selectedId
                        const selectedContact = contactPersons.find(
                          (contact) => contact.contactId === selectedId
                        );
                        if (selectedContact) {
                          updateContactDetails(selectedContact); // Update details based on selected contact
                        }
                      }}
                      onBlur={() => handleBlur("contactId")}
                    >
                      {contactPersons.map((contact) => (
                        <MenuItem
                          key={contact.contactId}
                          value={contact.contactId}
                        >
                          {contact.contactPerson}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* {touchedFields.contactId && errors.contactId && (
                      <FormHelperText>Contact Person is required</FormHelperText> // Display helper text
                    )} */}
                  </FormControl>
                </Grid>
              )}
              <Grid item container className="fieldWrapper">
                <TextField
                  name="companyName"
                  placeholder={t("AddParticipants.companyName")}
                  label={t("AddParticipants.companyName")}
                  variant="outlined"
                  value={contactDetails.companyName}
                  onChange={handleInputChange}
                  disabled
                />
              </Grid>
              <Grid item container className="fieldWrapper">
                <FormControl>
                  <TextField
                    name="address1"
                    placeholder={t("AddParticipants.address_placeholder")}
                    label={t("AddParticipants.address1")}
                    variant="outlined"
                    fullWidth
                    className="mb20"
                    value={contactDetails.address1}
                    disabled
                    InputProps={{ readOnly: true }}
                  />

                  <TextField
                    name="address2"
                    placeholder="Address"
                    label={t("AddParticipants.address2")}
                    variant="outlined"
                    value={contactDetails.address2}
                    disabled
                    InputProps={{ readOnly: true }}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                sm={12}
                className="fieldWrapper flex_columns_in_mobile"
              >
                <TextField
                  name="city"
                  placeholder={t("AddParticipants.city")}
                  label={t("AddParticipants.city")}
                  variant="outlined"
                  className="inlineSelect oneThird"
                  value={contactDetails.city}
                  disabled
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  name="state"
                  placeholder={t("AddParticipants.state")}
                  label={t("AddParticipants.state")}
                  variant="outlined"
                  className="inlineSelect  oneThird"
                  value={contactDetails.state}
                  disabled
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  name="zipCode"
                  placeholder={t("AddParticipants.zipCode")}
                  label={t("AddParticipants.zipCode")}
                  variant="outlined"
                  className="oneThird"
                  value={contactDetails.zipCode}
                  disabled
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} className="fieldWrapper">
                <TextField
                  fullWidth
                  type="PrimarContactNumber"
                  placeholder={t("AddParticipants.PrimarContactNumber")}
                  label={t("AddParticipants.PrimarContactNumber")}
                  name="PrimarContactNumber"
                  value={contactDetails.contactNo}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {contactDetails.sendSMSToPhone ===
                          contactDetails.contactNo && (
                            <Icon path={mdiMessageProcessing} size={1} />
                          )}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e: any) => handlePhoneNumberChange(e, "contactNo")}
                  disabled
                />
              </Grid>
              <Grid item xs={12} className="fieldWrapper">
                <TextField
                  fullWidth
                  type="AlternatecontactNumber"
                  placeholder={t("AddParticipants.AlternatecontactNumber")}
                  label={t("AddParticipants.AlternatecontactNumber")}
                  name="AlternatecontactNumber"
                  value={contactDetails.alternatePhoneNumber}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {contactDetails.sendSMSToPhone ===
                          contactDetails.alternatePhoneNumber && (
                            <Icon path={mdiMessageProcessing} size={1} />
                          )}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e: any) =>
                    handlePhoneNumberChange(e, "alternatePhoneNumber")
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={12} className="fieldWrapper">
                <TextField
                  fullWidth
                  type="email"
                  placeholder={t("AddParticipants.email")}
                  label={t("AddParticipants.email")}
                  name="AlternatecontactNumber"
                  value={contactDetails.email}
                  disabled
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              {/* <Grid
                item
                className="fieldWrapper"
                xs={12}
                gap={3}
                alignItems="center"
              >
                <label htmlFor="name"> Can receive SMS:</label>
                <SMSSwitch
                  checked={contactDetails.canRecieveSms}
                  onChange={(event) => handleChange(event.target.checked)}
                  inputProps={{ "aria-label": "receive sms" }}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} className="fieldWrapper">
                <FormControl className="inlineSelect">
                  <InputLabel id="sms_placeholder">
                    Send SMS to Phone
                  </InputLabel>
                  <Select
                    labelId="sms_placeholder"
                    id="sms"
                    label="Send SMS to Phone"
                    placeholder="SMS"
                    className="selectBox"
                    readOnly
                    value={contactDetails.sendSMSToPhone}
                  >
                    {contactDetails.contactNo && (
                      <MenuItem value={contactDetails.contactNo}>
                        Primary: {contactDetails.contactNo}
                      </MenuItem>
                    )}
                    {contactDetails.alternatePhoneNumber && (
                      <MenuItem value={contactDetails.alternatePhoneNumber}>
                        Alternate: {contactDetails.alternatePhoneNumber}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid> */}
              {/* <Grid item container className="fieldWrapper">
                <FormControl className="inlineSelect">
                  <InputLabel id="UserRole_placeholder">
                    User Role Type
                  </InputLabel>
                  <Select
                    labelId="UserRole_placeholder"
                    id="UserRole"
                    label="User Role Type"
                    placeholder="User Role Type"
                    className="selectBox"
                    //inputRef={userRoleTypeRef}
                    value={formDetails.userRoleType}
                    onChange={(e) => {
                      const selectedRole = parseInt(e.target.value as string,10);
                      console.log("Selected Role:", selectedRole); // Logging selected role value

                      handleUserRoleChange(e);
                      // Check if selected role is in LICENSED_USER_ROLES array
                      setShowLicensedUserToggle(LICENSED_USER_ROLES.includes(selectedRole)); // Change 'licensedUserRoleKey' to your specific key
                    }}
                    input={<OutlinedInput notched label="User Role Type" />}
                  >
                    {userTypes.map((userType) => (
                      <MenuItem key={userType.key} value={userType.key}>
                        {userType.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              {/* {showLicensedUserToggle && (
                <Grid
                  item
                  className="fieldWrapper"
                  xs={12}
                  gap={3}
                  alignItems="center"
                >
                  <label htmlFor="name">Licensed User:</label>
                  <SMSSwitch
                    checked={addParticipantDetails.IsLicensed}
                    onChange={(event) => handleToggleChange(event.target.checked)}
                    inputProps={{ "aria-label": "receive sms" }}
                  />
                </Grid>
              )} */}
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={styles.submitButtons + " submitButtons"}
        >
          <Button
            fullWidth
            variant="outlined"
            className="outline-button widthauto"
            onClick={handleDrawerToggle}
          >
            {t("AddParticipants.cancel_btn")}
          </Button>
          {isSearching ? null : (
            <Button
              fullWidth
              variant="contained"
              className="primary-button widthauto"
              onClick={handleSaveAddParticipantData}
              disabled={!(isFormValid || saveEnabled)}
              startIcon={
                savingData ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              }
            >
              {savingData ? t("AddParticipants.saving_btn") : t("AddParticipants.save_btn")}
            </Button>
          )}
        </Grid>
      </Box>
    </StyledDrawer>
  );
};

export default AddParticipants;
