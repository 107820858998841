import React, { useCallback, useEffect, useState, useTransition } from "react";
import styles from "./projects.module.scss";
import Icon from "@mdi/react";
import { mdiArrowLeftCircle, mdiCheck, mdiPencil } from "@mdi/js";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Snackbar,
  styled,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { IProject, ITabPanelProps } from "../../interfaces";
import {
  AddProject,
  AttachedDocuments,
  Participants,
  Schedule,
  Tasks,
  Materials,
  Equipments,
  Budget,
  LotDetails,
} from "../../containers";
import { useLocation, useNavigate } from "react-router-dom";
import { projectService, sharedService } from "../../api";
import { useTranslation } from "react-i18next";

const Projectdetail: React.FC = () => {
  function TabPanel(props: ITabPanelProps) {
    const { children, value, index } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tabpanel-${index}`}
        className={styles.tabpanel_container}
      >
        {value === index && <Box component="div">{children}</Box>}
      </div>
    );
  }
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleIconClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    
    event.preventDefault(); // Prevent the default form submission behavior
    if (isEditing) {
      // Save logic when exiting edit mode
      try {
        const currentProjectName = sessionStorage.getItem("ProjectName") as string;
        const newProjectName = projectNameValue;

        if (newProjectName === currentProjectName) {
          setSnackbarMessage("Please update the name.");
          setOpenSnackbar(true);
          return;
        }
        const param = {
          projectId: parseInt(projectId as string, 10),
          projectName: currentProjectName,
          userId: userId,
          customerId: customerId,
        };

        const response = await projectService.editProjectName(param);

        if (response.isSuccess) {
          setSnackbarMessage("Project name updated successfully.");
          setOpenSnackbar(true);
        }
        // } else {
        //   // Handle the error case (optional)
        //   setSnackbarMessage("Failed to update project.");
        //   setOpenSnackbar(true);
        // }
      } catch (error) {
        console.error("Error updating project name:", error);
        // setSnackbarMessage("");
        // setOpenSnackbar(true);
      }
    }
    setIsEditing(!isEditing); // Toggle edit mode
  };

  const CustomTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-flexContainer": {
      borderBottom: "0.4px solid rgb(0, 0, 0)",
    },
    "& .MuiTabs-indicator": {
      height: "5px",
      marginBottom: "-1px",
      borderRadius: "10px 10px 0px 0px",
      // backgroundColor: "#1814F3",
    },
    ".MuiButtonBase-root": {
      borderRadius: "10px 10px 0px 0px",
      padding: "0px 20px",
      minHeight: "35px",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 20px", // Adjust this width for small screens
        width: "auto",
      },
    },
    "& .Mui-selected": {
      backgroundColor: "var(--bgColor) !important",
      color: "var(--Text-color) !important",
    },
    "& .MuiTabs-scrollButtons": {
      padding: "0px",
    },
    "& .MuiTabs-scrollButtons.Mui-disabled": {
      display: "none !important",
    },
  }));

  const activeTab = JSON.parse(
    sessionStorage.getItem("ActiveTabIndex") || "{}"
  );
  const [tabValue, setTabValue] = useState(activeTab);

  const [projectNameValue, setProjectNameValue] = useState<string | null>("");
  //Handling the change of data for Tabs
  const handleChange = (evet: React.SyntheticEvent, newValue: number) => {
    sessionStorage.setItem("ActiveTabIndex", JSON.stringify(newValue));
    setTabValue(newValue);
  };

  const handleChangeprojectName = (evet: React.SyntheticEvent) => {
    sessionStorage.setItem("ProjectName", (evet.target as HTMLElement).textContent || '');
  };

  //Handling back button click for project list
  const navigate = useNavigate();
  // const handleProjectList = () => {
  //   navigate("/projects", { replace: true });
  // };
  const handleProjectList = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/projects", { replace: true }); // Fallback to the default page
    }
  };
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const userId = localStorage.getItem("userId");
  const location = useLocation();
  const projectId = location.state?.projectId;
  // State to hold the temporary name during editing
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (projectId) {
      (async () => {
        try {
          setUserRoles(await sharedService.getUserRolesPermissions());
          const data = await projectService.getProjectByProjectId(projectId);
          data.readProjectDetails.files = [];
          setProjectNameValue(data.readProjectDetails.projectName);
        } catch (error: any) {
          setError(error.response.data.errors.join(", "));
        }
      })();
    }
  }, [projectId]); // Add projectId as a dependency if it might change

  const allTabs = [
    { label: t("Projectdetail.project_detail_tab"), component: <AddProject /> },
    { label: t("Projectdetail.lot_tab"), component: <LotDetails /> },
    { label: t("Projectdetail.participants_tab"), component: <Participants /> },
    { label: t("Projectdetail.schedule_tab"), component: <Schedule /> },
    { label: t("Projectdetail.tasks_tab"), component: <Tasks /> },
    { label: t("Projectdetail.materials_tab"), component: <Materials /> },
    { label: t("Projectdetail.equipments_tab"), component: <Equipments /> },
    { label: t("Projectdetail.documents_tab"), component: <AttachedDocuments /> },
    { label: t("Projectdetail.budget_tab"), component: <Budget /> },
  ];

  const customerId = parseInt(localStorage.getItem("customerId") as string, 10);
  const [userRoles, setUserRoles] = useState<any>(null);

  const excludedIndices = new Set(userRoles?.project_details_tab);
  const visibleTabs = allTabs.filter((_, index) => excludedIndices.has(index));

  return (
    <div className={styles.ProjectDetails}>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <h3 className={styles.page_title}>
            {" "}
            <IconButton onClick={handleProjectList} sx={{ padding: 0 }}>
              <Icon path={mdiArrowLeftCircle} aria-label="back" size={1} />
            </IconButton>{" "}

            <span
              className={`${styles.projectName} ${isEditing ? styles.editing : ''}`}
              contentEditable={isEditing}
              suppressContentEditableWarning={true} // to suppress React warning
              onInput={handleChangeprojectName}
            >
              {projectNameValue}
            </span>
            {" "}
            <IconButton onClick={handleIconClick}>
              <Icon
                path={isEditing ? mdiCheck : mdiPencil} // Show checkmark when editing
                className={styles.editIcon}
                aria-label={isEditing ? "save" : "edit"}
                size={1}
              />
            </IconButton>
          </h3>
        </Grid>
      </Grid>
      <Grid container item className={styles.project_details_container}>
        <Grid item xs={12}>
          <CustomTabs
            value={tabValue}
            className={styles.tab_title}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="basic tabs example"
          >
            {visibleTabs.map((tab: any, index: any) => (
              <Tab key={index} label={tab.label} />
            ))}
          </CustomTabs>
          {visibleTabs.map((tab: any, index: any) => (
            <TabPanel key={index} value={tabValue} index={index}>
              {tab.component}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Projectdetail;